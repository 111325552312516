import { Box, Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { MissingImage } from ".";

import { imageEndpoint } from "@lexgo/types/constants";
import { ReactElement } from "react";
import { downloadFile } from "../pages/helpers";

// Icons
import DownloadIcon from "@mui/icons-material/Download";

// Types
import { JobRequest } from "@lexgo/types/Jobs";
import { FilePreview } from "./FilePreview";
import { useAuth0 } from "@auth0/auth0-react";

export const StatusChips: Record<JobRequest["status"], ReactElement> = {
	paymentPending: (
		<Tooltip title="Awaiting payment from buyer">
			<Chip label="Payment Pending" color="warning" />
		</Tooltip>
	),
	paid: (
		<Tooltip title="Seller is working on this job">
			<Chip label="Paid" color="info" />
		</Tooltip>
	),
	changesRequested: (
		<Tooltip title="Buyer has requested changes">
			<Chip label="Changes Requested" color="warning" />
		</Tooltip>
	),
	newRevision: (
		<Tooltip title="Seller has provided a new revision">
			<Chip label="New Revision" color="warning" />
		</Tooltip>
	),
	cancelled: (
		<Tooltip title="Job was cancelled">
			<Chip label="Cancelled" color="error" />
		</Tooltip>
	),
	completed: (
		<Tooltip title="Job completed and product delivered">
			<Chip label="Completed" color="success" />
		</Tooltip>
	),
};

export const JobProperties = ({ job }: { job: JobRequest }) => (
	<>
		<Grid item>
			<Typography variant="h4">{job.listing.name}</Typography>
			<Chip label={`$${job.listing.price}`} style={{ marginRight: 5 }} />
			{StatusChips[job.status]}
		</Grid>
		<Grid item>
			<Typography variant="body2" color="text.secondary">
				Bought: {new Date(job.requestedAt).toDateString()}
			</Typography>
		</Grid>

		<Grid item>
			<Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
				{job.listing.description}
			</Typography>
		</Grid>
		<br />
		<Grid item>
			<Typography variant="h5">Answers</Typography>
			<Grid container direction="row">
				{job.listing.questions.map((question, i) => (
					<Grid item key={i} xs={12}>
						<Typography variant="caption" style={{ color: "grey" }}>
							{question}
						</Typography>
						<Typography variant="body2">{job.answers[i]}</Typography>
					</Grid>
				))}
			</Grid>
		</Grid>
	</>
);

export const JobImage = ({ image }: { image?: string }) => (
	<Box borderBottom={0} borderRadius={1} style={{ backgroundColor: "rgba(0,0,0, 0.2)", height: 150, width: "100%" }}>
		{image === undefined ? (
			<MissingImage style={{ width: "100%", height: 150 }} />
		) : (
			<img src={`${imageEndpoint}/${image}/256`} style={{ borderRadius: 2, height: "100%", width: "100%", objectFit: "cover" }} />
		)}
	</Box>
);

export const JobRevisions = ({ job }: { job: JobRequest }) => {
	const auth0 = useAuth0();
	return (
		<Grid container direction="row" spacing={1}>
			{job.revisions
				.sort((a, b) => a.submittedAt - b.submittedAt)
				.map((revision, i) => (
					<Grid item key={i}>
						<FilePreview id={revision.files[0].previewImageId} placement={"right-start"}>
							<Button
								variant="outlined"
								onClick={() => {
									downloadFile(job.id, revision, auth0);
								}}
							>
								<DownloadIcon />
								<Typography style={{ marginLeft: 5 }} variant="caption">{`Revision ${i + 1}`}</Typography>
							</Button>
						</FilePreview>
					</Grid>
				))}
		</Grid>
	);
};
