import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "../../components";
import { getAuthHeaders } from "../helpers";

export const StripeResponse = ({ whatHappened }: { whatHappened: "checkoutSuccess" | "checkoutCancel" }) => {
	const auth0 = useAuth0();
	const navigate = useNavigate();
	useEffect(() => {
		(async () => {
			const sessionId = new URLSearchParams(window.location.search).get("sessionId");
			if (sessionId !== null) {
				await fetch(`https://api.lexgo.co.nz/v1/stripe/${whatHappened}`, {
					headers: await getAuthHeaders(auth0),
					method: "POST",
					body: JSON.stringify({ sessionId }),
				});
			}
			whatHappened === "checkoutSuccess" ? navigate("/buyer") : navigate("/");
		})();
	}, []);
	return <LoadingPage />;
};
