import { Component } from "react";

import { Grid, IconButton, Modal, Box, Button } from "@mui/material";

import type { NewListing } from "@lexgo/types/Jobs";
import { StatusSnackbar } from "../../../components/StatusSnackbar";

import { ListingPropertiesForm } from "./ListingPropertiesForm";

// Icons
import CloseIcon from "@mui/icons-material/Close";

type State = {
	newListing: NewListing;
	success?: string;
	error?: string;
	test?: any;
	invalid: boolean;
};

type Props = {
	onSubmit: (newListing: NewListing) => void;
	open: boolean;
	onClose: () => void;
};

export default class ModalListing extends Component<Props> {
	state: State = {
		newListing: {
			name: "",
			description: "",
			price: 0,
			questions: [],
		},
		invalid: false,
	};

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					style={{ padding: 5, borderRadius: 10 }}
					sx={{
						bgcolor: "background.paper",
					}}
					// Second box for scrollbar
				>
					<Box style={{ overflow: "scroll", maxHeight: "80vh", width: 350, padding: 25 }}>
						<StatusSnackbar success={this.state.success} error={this.state.error} onClose={() => this.setState({ success: undefined, error: undefined })} />
						<Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
							<Grid item>
								<h2>Create a new listing</h2>
							</Grid>
							<Grid item>
								<IconButton onClick={this.props.onClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
						<form
							onSubmit={(event) => {
								event.preventDefault();
								this.props.onSubmit(this.state.newListing);
								this.setState({ listing: undefined });
							}}
						>
							<Grid container spacing={3} direction="column" justifyContent="center">
								<Grid item>
									<ListingPropertiesForm
										listing={this.state.newListing}
										onChange={({ updatedListing, invalid }) => this.setState({ newListing: updatedListing, invalid })}
									/>
								</Grid>
								<Grid item>
									<Grid item>
										<Button
											type="submit"
											variant="contained"
											color="secondary"
											style={{ color: this.state.invalid ? "grey" : undefined }}
											size="large"
											disabled={this.state.invalid}
										>
											Create listing
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Box>
			</Modal>
		);
	}
}
