import { useEffect, useState } from "react";

import {
	Grid,
	Typography,
	Button,
	Divider,
	Select,
	MenuItem,
	FormHelperText,
	Paper,
	InputAdornment,
	TextField,
	Avatar,
	LinearProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Switch,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import { fetchSellerProfile, getAuthHeaders } from "../helpers";
import { imageEndpoint } from "@lexgo/types/constants";

// Components
import { StatusSnackbar, UploadWrapper, LoadingComponent } from "../../components";

// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// Types
import type { SellerProfile } from "@lexgo/types/Seller";
import type Stripe from "stripe";
import { useNavigate } from "react-router-dom";
import { Spacer } from "../../components/Spacer";

const avalibleRoles: SellerProfile["role"][] = ["Law Firm", "Solicitor", "Paralegal", "Law Student", "Law Grad", "Other"];
const avalibleCountries: SellerProfile["location"][] = ["Auckland", "Hamilton", "Wellington", "Christchurch", "Dunedin", "Other"];

export const Profile = () => {
	const [sellerProfile, setSellerProfile] = useState<SellerProfile>();
	const [uneditedSellerProfile, setUneditedSellerProfile] = useState<SellerProfile>();
	const [stripeProfile, setStripeProfile] = useState<Stripe.Account>();

	const [linkStripePrompt, setLinkStripePrompt] = useState(false);

	const [status, setStatus] = useState<{ error?: string; success?: string; loading: boolean }>({ loading: false });
	const [changed, setChanged] = useState(false);

	const auth0 = useAuth0();
	const navigate = useNavigate();

	const connectStripe = async () => {
		const accountLink: Stripe.AccountLink = await fetch("https://api.lexgo.co.nz/v1/stripe", {
			headers: await getAuthHeaders(auth0),
			method: "POST",
		}).then((res) => res.json());
		if (accountLink.url === undefined) {
			// @ts-expect-error err does not exist on AccountLink but will if there is a error returned from stripe
			if (accountLink.err !== undefined) this.setState({ error: accountLink.err });
		} else window.location.href = accountLink.url;
	};

	const fetchOrCreateProfile = async () => {
		const sellerRes = await fetchSellerProfile(auth0, true);
		if (sellerRes.err !== undefined) return setStatus({ error: sellerRes.err, loading: status.loading });

		return sellerRes._;
	};

	const fetchStripeProfile = async (stripeAccountId: string) => {
		const url = new URL("https://api.lexgo.co.nz/v1/stripe");
		url.searchParams.append("stripeAccountId", stripeAccountId);
		const stripeProfile = await fetch(url.href, {
			headers: await getAuthHeaders(auth0),
		}).then((res) => res.json());

		return stripeProfile;
	};

	useEffect(() => {
		fetchOrCreateProfile()
			.then(async (sellerProfile) => {
				if (sellerProfile === undefined) return;
				if (sellerProfile.stripeAccountId === undefined) return setLinkStripePrompt(true);

				const stripeProfile = await fetchStripeProfile(sellerProfile.stripeAccountId).catch((err) => setStatus({ error: err.message, loading: status.loading }));
				if (stripeProfile === undefined) return;

				setStatus({ loading: false });
				setUneditedSellerProfile(sellerProfile);
				setSellerProfile(sellerProfile);
				setStripeProfile(stripeProfile);
			})
			.catch((err) => setStatus({ error: err.message, loading: status.loading }));
	}, []);

	if (sellerProfile?.stripeAccountId === undefined) {
		return (
			<>
				<StatusSnackbar
					success={status.success}
					error={status.error}
					onClose={() => setStatus({ success: undefined, error: undefined, loading: status.loading })}
				/>
				<LoadingComponent />
				<Dialog open={linkStripePrompt} onClose={() => navigate("/")}>
					<DialogTitle>{"Stripe Required"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">You need to provide details for your stripe account to continue.</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => navigate("/")}>Go Back</Button>
						<Button onClick={connectStripe} autoFocus>
							Continue to Stripe
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

	const updateProfile = (partialProfile: Partial<SellerProfile>) => {
		setSellerProfile({ ...sellerProfile, ...partialProfile });
		setChanged(true);
	};

	const saveChanges = async () => {
		setStatus({ loading: true });
		const updateRes = await fetch("https://api.lexgo.co.nz/v1/seller/profile", {
			body: JSON.stringify(sellerProfile),
			headers: await getAuthHeaders(auth0),
			method: "PUT",
		});

		if (!updateRes.ok) return setStatus({ loading: false, success: `An error occoured while saving changes... ${updateRes.json()}` });

		setStatus({ loading: false, success: "Saved profile!" });
		setChanged(false);
		setUneditedSellerProfile(sellerProfile);
	};

	const discardChanges = () => {
		setChanged(false);
		setSellerProfile(uneditedSellerProfile);
	};

	const onImageUpload: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
		if (event.target.files === null) return;
		const image = event.target.files[0];

		// Make sure uploaded file is a image
		if (!image.type.includes("image")) return setStatus({ error: "Only images are supported", loading: status.loading });
		setStatus({ loading: true });

		const res = await fetch("https://api.lexgo.co.nz/v1/image", {
			headers: await getAuthHeaders(auth0),
		});

		if (!res.ok) return setStatus({ loading: false, error: await res.text() });

		const { uploadURL } = await res.json();

		const formData = new FormData();
		formData.append("file", image);
		const uploadResult = await fetch(uploadURL, {
			method: "POST",
			body: formData,
		});

		if (!uploadResult.ok) return setStatus({ loading: false, error: await uploadResult.text() });

		setStatus({ success: `Uploaded image!`, loading: status.loading });

		const { result }: { result: { id: string } } = await uploadResult.json();

		const newProfile = { ...sellerProfile, image: result.id };

		// Save the Changes
		const updateRes = await fetch("https://api.lexgo.co.nz/v1/seller/profile", {
			body: JSON.stringify(newProfile),
			headers: await getAuthHeaders(auth0),
			method: "PUT",
		});

		if (!updateRes.ok) return setStatus({ success: `An error occoured while updating profile image... ${updateRes.json()}`, loading: status.loading });

		// Delete the previous image if there was one
		if (sellerProfile?.image !== undefined) {
			const url = new URL("https://api.lexgo.co.nz/v1/image");
			url.searchParams.append("id", sellerProfile?.image);
			await fetch(url.href, {
				headers: await getAuthHeaders(auth0),
				method: "DELETE",
			});
		}

		setStatus({ loading: false, success: "Updated profile image" });
		setUneditedSellerProfile(newProfile);
		setSellerProfile(newProfile);
	};

	const user = auth0.user;
	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={changed ? { border: 1, borderColor: "orange", borderRadius: 1 } : {}}
			style={{ padding: 32, paddingBottom: 31 }}
		>
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ success: undefined, error: undefined, loading: status.loading })} />
			<Grid item>
				<UploadWrapper style={{ height: "128px", width: "128px" }} onUpload={onImageUpload}>
					<Avatar
						alt={user?.name}
						src={sellerProfile.image !== undefined ? `${imageEndpoint}/${sellerProfile.image}/256` : "_"}
						style={{ height: "128px", width: "128px" }}
					/>
				</UploadWrapper>
			</Grid>
			{status.loading ? <LinearProgress /> : null}
			<br />
			<Grid item>
				<TextField
					variant="outlined"
					size="small"
					value={sellerProfile.name}
					style={{ width: 164, textAlign: "center" }}
					inputProps={{ style: { textAlign: "center" } }}
					onChange={(event) => updateProfile({ name: event.target.value })}
				/>
			</Grid>
			<Grid item>
				<p style={{ margin: 0, marginTop: 16, marginBottom: 16 }}>{user?.email}</p>
			</Grid>
			<Divider style={{ width: "100%" }}>Stripe</Divider>
			<Grid item>
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<Grid container direction="row" alignItems="center">
							<Typography style={{ marginRight: 5 }}>Charges</Typography>
							{stripeProfile?.charges_enabled ? <CheckIcon style={{ color: "green", marginBottom: 4 }} /> : <CloseIcon style={{ color: "red", marginBottom: 2 }} />}
						</Grid>
					</Grid>
					<Grid item style={{ marginLeft: 10 }}>
						<Grid container direction="row" alignItems="center">
							<Typography style={{ marginRight: 5 }}>Payouts</Typography>
							{stripeProfile?.payouts_enabled ? <CheckIcon style={{ color: "green", marginBottom: 4 }} /> : <CloseIcon style={{ color: "red", marginBottom: 2 }} />}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Divider style={{ width: "100%" }}>Profile</Divider>
			<Grid item>
				<Typography style={{ marginLeft: 8 }}>
					Accepting New Jobs:
					<Switch
						style={{ color: !sellerProfile.acceptingNewJobs ? "red" : undefined }}
						color="success"
						checked={sellerProfile.acceptingNewJobs}
						onChange={({ target }) => updateProfile({ acceptingNewJobs: target.checked })}
					/>
				</Typography>
				<Spacer s={15} />
				<Typography
					style={{
						display: "flex",
						alignItems: "center",
						flexWrap: "wrap",
					}}
				>
					<PersonIcon style={{ marginRight: 15 }} />
					Joined {new Date(sellerProfile.created).toDateString().split(" ").slice(1).join(" ")}
				</Typography>
				<Spacer s={15} />
				<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
					<Grid item>
						<LocationOnIcon />
					</Grid>
					<Grid item>
						<Select
							variant="standard"
							value={sellerProfile.location}
							onChange={(event) => updateProfile({ location: event.target.value as SellerProfile["location"] })}
							error={sellerProfile.location === undefined}
							fullWidth={true}
							required={true}
							MenuProps={{
								// @ts-expect-error // Fix https://stackoverflow.com/questions/61356503/material-ui-multiselect-popup-anchor-moving-on-select
								getContentAnchorEl: () => null,
							}}
						>
							{avalibleCountries.map((location) => (
								<MenuItem key={location} value={location}>
									{location}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error={sellerProfile.location === undefined}>A location must be selected.</FormHelperText>
					</Grid>
				</Grid>
				<Spacer s={15} />
				<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
					<Grid item>
						<CardMembershipIcon />
					</Grid>
					<Grid item>
						<Select
							variant="standard"
							value={sellerProfile.role}
							onChange={(event) => updateProfile({ role: event.target.value as SellerProfile["role"] })}
							error={sellerProfile.role === undefined}
							fullWidth={true}
							required={true}
							MenuProps={{
								// @ts-expect-error // Fix https://stackoverflow.com/questions/61356503/material-ui-multiselect-popup-anchor-moving-on-select
								getContentAnchorEl: () => null,
							}}
						>
							{avalibleRoles.map((role) => (
								<MenuItem key={role} value={role}>
									{role}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error={sellerProfile.role === undefined}>A role must be selected.</FormHelperText>
					</Grid>
				</Grid>
				<Spacer s={15} />
				<Typography
					style={{
						display: "flex",
						alignItems: "center",
						flexWrap: "wrap",
						fontSize: 20,
					}}
					variant="h5"
				>
					<DescriptionIcon style={{ marginRight: 15 }} />
					Description
				</Typography>
				<Spacer s={5} />
				<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
					<Grid item>
						<Paper style={{ padding: "10px" }}>
							<TextField
								variant="standard"
								value={sellerProfile.description}
								style={{ minWidth: "100px", minHeight: "50px", fontSize: "h1" }}
								multiline
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<EditIcon fontSize="small" />
										</InputAdornment>
									),
									disableUnderline: true,
								}}
								rows={changed ? 3 : 5}
								onChange={(event) => updateProfile({ description: event.target.value })}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
			{changed && (
				<Grid container style={{ marginTop: 5 }} direction="row" justifyContent="center" spacing={2}>
					<Grid item>
						<Button variant="contained" size="small" color="secondary" onClick={saveChanges}>
							Save
						</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" size="small" color="error" onClick={discardChanges}>
							Discard
						</Button>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
