import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Toolbar, Typography, Avatar, Button } from "@mui/material";

// Icons
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";

export const UserAppBar = (props: { seller?: boolean }) => {
	const auth0 = useAuth0();
	const navigate = useNavigate();

	const toStripeDashboard = async () => {
		const url = await fetch("https://api.lexgo.co.nz/v1/stripe/dashboard", {
			headers: { Authorization: `Bearer ${await auth0.getAccessTokenSilently()}` },
		}).then((res) => res.json());
		window.open(url, "_blank");
	};

	const userCanSell = auth0.user?.["https://lexgo.com/roles"]?.includes("Seller") || false;

	return (
		<AppBar
			enableColorOnDark
			position="static"
			style={{
				flexGrow: 1,
			}}
		>
			<Toolbar>
				<img
					src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/29fab79d-51c0-45e7-4d21-2580adfd0700/256"
					style={{
						maxWidth: "100px",
						flexGrow: 1,
						cursor: "pointer",
					}}
					onClick={() => navigate("/")}
				/>
				{/* This span literally just exists to right align the below content */}
				<span style={{ marginLeft: "auto" }} />
				<div style={{ marginRight: "40px" }}>
					{props.seller && (
						<Button variant="contained" color="info" onClick={() => navigate("/buyer")}>
							SWITCH TO BUYING
						</Button>
					)}
					{userCanSell && !props.seller && (
						<Button variant="contained" color="info" onClick={() => navigate("/seller")}>
							SWITCH TO SELLING
						</Button>
					)}
				</div>
				<Avatar style={{ marginRight: "10px" }} alt={auth0.user?.name} imgProps={{ referrerPolicy: "no-referrer" }} src={auth0.user?.picture} />
				<Typography style={{ marginRight: "30px" }} variant="body1">
					{auth0.user?.name}
				</Typography>
				{props.seller ? (
					<Button style={{ marginRight: "10px" }} variant="contained" color="info" onClick={toStripeDashboard}>
						Stripe Dashboard
					</Button>
				) : null}
				<Button variant="contained" color="secondary" onClick={() => auth0.logout()}>
					<span style={{ marginRight: 5 }}>{"Sign Out"}</span>
					<ExitToAppIcon />
				</Button>
			</Toolbar>
		</AppBar>
	);
};
