import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { Auth0ProviderWithHistory } from "./components/Auth0ProviderWtihHistory";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_51H3xdGLFkYMe0EbdtmLPsODzwHlpo871rpq1C1gVVv1e8DrCXhMBwaGtC6xjhp0X0UQjVMd3EVgj4BQDLM5LBjNk00zQhFyOAx");

createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<Elements stripe={stripePromise}>
					<App />
				</Elements>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	</React.StrictMode>
);
