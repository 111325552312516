import { CardActionArea, CardMedia, CardContent, Typography, CardActions, Grid, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { HoverCard, MissingImage, StatusSnackbar } from "../../components";

import { imageEndpoint } from "@lexgo/types/constants";
import { JobInfo } from "./JobInfo";

// Types
import type { JobRequest, PendingJobRequest } from "@lexgo/types/Jobs";

// Icons
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SellerProfileButton, StatusChips } from "../../components";

export const PendingJobCard = ({ job }: { job: PendingJobRequest }) => {
	const [status, setStatus] = useState<{ error?: string; success?: string }>({});

	const { listing } = job;
	return (
		<HoverCard style={{ width: 250, borderRadius: 2 }}>
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({})} />
			<CardActionArea style={{ cursor: "unset" }}>
				{listing.image ? (
					<CardMedia component="img" height="140" image={`${imageEndpoint}/${listing.image}/256`} />
				) : (
					<MissingImage style={{ height: 150, width: "100%", backgroundColor: "main" }} />
				)}
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{listing.name}
					</Typography>
					<Typography variant="body2" color="text.secondary" style={{ height: 100, overflow: "hidden", whiteSpace: "pre-line" }}>
						{listing.description}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions style={{ padding: 10, paddingLeft: 20 }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
					<SellerProfileButton id={job.sellerId} />
					<Tooltip title="Cancel Checkout">
						<IconButton style={{ color: "red", borderRadius: 2 }} onClick={() => (window.location.href = job.cancelUrl.replace("{CHECKOUT_SESSION_ID}", job.id))}>
							<DeleteForeverIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Resume Checkout">
						<IconButton style={{ color: "green", borderRadius: 2 }} onClick={() => (window.location.href = job.checkoutUrl)}>
							<ShoppingCartIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			</CardActions>
		</HoverCard>
	);
};

export const ActiveJobCard = ({ job }: { job: JobRequest }) => {
	const [open, setOpen] = useState(false);

	const { listing } = job;
	if (open) return <JobInfo job={job} onClose={() => setOpen(false)} />;
	return (
		<HoverCard style={{ width: 260, borderRadius: 2 }}>
			<CardActionArea onClick={() => setOpen(true)}>
				{listing.image ? (
					<CardMedia component="img" height="140" image={`${imageEndpoint}/${listing.image}/256`} />
				) : (
					<MissingImage style={{ height: 150, width: "100%", backgroundColor: "main" }} />
				)}
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{listing.name}
					</Typography>
					<Typography variant="body2" color="text.secondary" style={{ height: 100, overflow: "hidden", whiteSpace: "pre-line" }}>
						{listing.description}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions style={{ padding: 10 }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
					<Grid item>
						<SellerProfileButton id={job.sellerId} />
					</Grid>
					<Grid item>{StatusChips[job.status]}</Grid>
				</Grid>
			</CardActions>
		</HoverCard>
	);
};
