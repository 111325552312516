import { Grid, TextField, InputAdornment, IconButton, Typography, Button, Switch } from "@mui/material";
import { ChangeEvent, Component } from "react";
import type { Listing, NewListing } from "@lexgo/types/Jobs";

import DeleteIcon from "@mui/icons-material/Delete";

export type OnListingPropsChanged = (info: { updatedListing: NewListing | Listing; invalid: boolean }) => void;
type Props = {
	listing: NewListing | Listing;
	onChange: OnListingPropsChanged;
};

export class ListingPropertiesForm extends Component<Props> {
	setListingQuestion = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
		const questions = [...this.props.listing.questions];
		questions[index] = event.target.value;
		this.setListing({ questions });
	};
	addQuestion = () => {
		const questions = [...this.props.listing.questions];
		questions.push("");
		this.setListing({ questions });
	};
	removeQuestion = (index: number) => () => {
		const questions = [...this.props.listing.questions];
		questions.splice(index, 1);
		this.setListing({ questions });
	};
	setListingProp = (prop: keyof NewListing) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.setListing({ [prop]: event.target.value });
	setListing = (partialListing: Partial<Listing>) => {
		const updatedListing = { ...this.props.listing, ...partialListing };
		const invalid =
			updatedListing.name === "" ||
			updatedListing.name.length > 16 ||
			isNaN(updatedListing.price) ||
			updatedListing.price < 5 ||
			this.props.listing.questions.some((question) => question === "");
		updatedListing.name = updatedListing.name.substring(0, 16);
		this.props.onChange({ updatedListing, invalid });
	};

	render() {
		const listing = this.props.listing;
		if (listing.questions.length === 0) this.addQuestion();
		return (
			<>
				<Grid spacing={2} container direction="column" justifyContent="center">
					<Grid item>
						<TextField
							style={{ width: 240, marginRight: 10 }}
							value={listing.name || ""}
							onChange={this.setListingProp("name").bind(this)}
							error={listing.name === "" || listing.name.length > 16}
							label="Name"
							required
							autoFocus
						/>
						<TextField
							label="Price"
							style={{ width: 100 }}
							inputProps={{ inputMode: "numeric" }}
							InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
							onChange={(event) => !isNaN(+event.target.value) && this.setListing({ price: +event.target.value })}
							error={isNaN(listing.price) || listing.price < 5}
							value={listing.price}
							InputLabelProps={{
								shrink: true,
							}}
							helperText="Minimum $5"
						/>
						<Typography style={{ marginTop: -16 }}>
							Enabled:
							<Switch color="success" checked={listing.disabled !== true} onChange={({ target }) => this.setListing({ disabled: target.checked !== true })} />
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h4">Description</Typography>
						<Typography variant="caption">This should include your estimated time to deliver & the maximum revisions you are offering</Typography>
					</Grid>
					<Grid item>
						<TextField
							style={{ width: 300 }}
							value={listing.description || ""}
							onChange={this.setListingProp("description").bind(this)}
							label="Description"
							multiline
							minRows={2}
						/>
					</Grid>
					<Grid item>
						<Typography variant="h4">Questions</Typography>
						<Typography variant="caption">You should provide one or more to the buyer so they can better inform you of what they want..</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={2}>
							{listing.questions.map((question, i) => (
								<Grid item key={i}>
									<TextField
										style={{ width: 260 }}
										value={question || ""}
										error={question === ""}
										onChange={this.setListingQuestion(i).bind(this)}
										label="Question for buyer"
										multiline
										minRows={2}
									/>
									{i !== 0 ? (
										<IconButton aria-label="delete" color="error" style={{ marginTop: 16 }} onClick={this.removeQuestion(i).bind(this)}>
											<DeleteIcon />
										</IconButton>
									) : null}
								</Grid>
							))}
						</Grid>
						<br />
						<Button aria-label="add" variant="contained" onClick={this.addQuestion.bind(this)}>
							Add Question
						</Button>
					</Grid>
				</Grid>
			</>
		);
	}
}
