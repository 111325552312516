import { Avatar, Divider, Grid, Typography } from "@mui/material";

import { imageEndpoint } from "@lexgo/types/constants";

// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

// Types
import type { SellerProfile } from "@lexgo/types/Seller";

const magicDivStyle: React.CSSProperties = {
	display: "flex",
	alignItems: "center",
	flexWrap: "wrap",
	marginBottom: 5,
};

export const SellerPreviewModule = (props: { sellerProfile: SellerProfile }) => {
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center">
			<Grid item>
				<Avatar
					src={props.sellerProfile.image !== undefined ? `${imageEndpoint}/${props.sellerProfile.image}/256` : "_"}
					style={{ height: "128px", width: "128px" }}
				/>
			</Grid>
			<br />
			<Grid item>
				<Typography variant="h5">{props.sellerProfile.name}</Typography>
			</Grid>
			<br />
			<Divider style={{ width: "100%", fontSize: 15 }}>Seller Info</Divider>
			<br />
			<Grid item style={{ height: 64, width: 256, textAlign: "center" }}>
				{props.sellerProfile.description}
			</Grid>
			<Grid item style={magicDivStyle}>
				<PersonIcon />
				<span style={{ marginLeft: 4 }}>Joined On</span>
			</Grid>
			<Grid item>
				<Typography variant="h5"> {new Date(props.sellerProfile.created).toDateString().split(" ").slice(1).join(" ")}</Typography>
			</Grid>
			<br />
			<br />

			<Grid item style={magicDivStyle}>
				<LocationOnIcon />
				<span style={{ marginLeft: 4 }}>Location</span>
			</Grid>
			<Grid item>
				<Typography variant="h5">{props.sellerProfile.location}</Typography>
			</Grid>
			<br />
			<br />
			<Grid item style={magicDivStyle}>
				<CardMembershipIcon />
				<span style={{ marginLeft: 4 }}>Role</span>
			</Grid>
			<Grid item>
				<Typography variant="h5">{props.sellerProfile.role}</Typography>
			</Grid>
			<br />
		</Grid>
	);
};
