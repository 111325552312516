import { useAuth0 } from "@auth0/auth0-react";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { AppBar, Divider, Grid, Paper, Tab, Typography } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { LoadingComponent, UserAppBar } from "../../components";
import { ActiveJobCard } from "./Jobs";

// Types
import type { JobRequest } from "@lexgo/types/Jobs";
import { getJobs, isActiveJob, isCancelledJob, isCompletedJob } from "../helpers";

enum BuyerTabs {
	PaymentPending = "Incomplete Purchases",
	Active = "In Progress",
	Completed = "Completed",
	Cancelled = "Cancelled",
}

// set the defaults
export const JobContext = createContext(() => {});

export const Buyer = () => {
	const [selectedTab, setTab] = useState(BuyerTabs.Active);
	const [state, setState] = useState<{ loading: boolean; jobs: JobRequest[] }>({ loading: true, jobs: [] });
	const columnWidth = "310px";

	const auth0 = useAuth0();

	const update = () => {
		getJobs(auth0, "buyer").then((jobs) => setState({ loading: false, jobs }));
	};

	useEffect(update, [auth0]);

	// Disabled returning pending jobs in backend
	// const pendingJobs = state.jobs.filter(isPaymentPendingJob);
	// const hasPendingJobs = pendingJobs.length !== 0;
	// if (!state.loading && selectedTab === BuyerTabs.PaymentPending && !hasPendingJobs) setTab(BuyerTabs.Active);

	const activeJobs = state.jobs.filter(isActiveJob);
	const completedJobs = state.jobs.filter(isCompletedJob);
	const cancelledJobs = state.jobs.filter(isCancelledJob);

	return (
		<>
			<UserAppBar />
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				style={{
					margin: 0,
					width: "100%",
				}}
			>
				<Grid item style={{ width: "950px" }}>
					<Paper elevation={3} style={{ height: "800px", overflowX: "hidden", overflowY: "auto" }}>
						<TabContext value={selectedTab}>
							<AppBar position="static" enableColorOnDark>
								{state.loading ? (
									<LoadingComponent />
								) : (
									<TabList indicatorColor="secondary" textColor="secondary" onChange={(e, value) => setTab(value)}>
										{/* {hasPendingJobs && <Tab label={BuyerTabs.PaymentPending} value={BuyerTabs.PaymentPending} />} */}
										<Tab color="white" label={BuyerTabs.Active} value={BuyerTabs.Active} />
										{completedJobs.length !== 0 && <Tab label={BuyerTabs.Completed} value={BuyerTabs.Completed} />}
										{cancelledJobs.length !== 0 && <Tab label={BuyerTabs.Cancelled} value={BuyerTabs.Cancelled} />}
									</TabList>
								)}
							</AppBar>
							{/* <TabPanel value={BuyerTabs.PaymentPending}>
								<Grid container direction="row" spacing={4}>
									{pendingJobs.map((job, i) => (
										<Grid item key={i}>
											<JobContext.Provider value={update}>
												<PendingJobCard job={job} />
											</JobContext.Provider>
										</Grid>
									))}
								</Grid>
							</TabPanel> */}
							<TabPanel value={BuyerTabs.Active}>
								{activeJobs.length === 0 ? (
									<Typography variant="h6" style={{ textAlign: "center" }}>
										You have no jobs in progress
									</Typography>
								) : (
									<Grid container direction="row" spacing={4}>
										{activeJobs.map((job, i) => (
											<Grid item key={i}>
												<JobContext.Provider value={update}>
													<ActiveJobCard job={job} />
												</JobContext.Provider>
											</Grid>
										))}
									</Grid>
								)}
							</TabPanel>
							{completedJobs.length !== 0 && (
								<TabPanel value={BuyerTabs.Completed}>
									<Grid container direction="row" spacing={4}>
										{completedJobs.map((job, i) => (
											<Grid item key={i}>
												<ActiveJobCard job={job} />
											</Grid>
										))}
									</Grid>
								</TabPanel>
							)}
						</TabContext>
					</Paper>
				</Grid>
				<Grid item style={{ width: columnWidth }}>
					<Grid container direction="column" justifyContent="center" alignItems="center">
						<Grid item style={{ width: columnWidth }}>
							<Paper elevation={3} style={{ padding: "32px", height: "390px" }}>
								<Typography variant="h6">Notifications</Typography>
								<Divider style={{ width: "100%", height: "2px" }} />
								<Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
									<Typography variant="h6">Coming Soon</Typography>
								</Grid>
							</Paper>
						</Grid>
						<br />
						<Grid item style={{ width: columnWidth }}>
							<Paper elevation={3} style={{ padding: "32px", height: "390px" }}>
								<Typography variant="h6">Refer-A-Friend</Typography>
								<Divider style={{ width: "100%", height: "2px" }} />
								<Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
									<Typography variant="h6">Coming Soon</Typography>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
