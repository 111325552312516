import { Card, CardContent, Grid, Typography, Divider, CardActions, Tooltip, Button, Box, IconButton, Modal, TextField } from "@mui/material";
import { JobImage, JobProperties, JobRevisions } from "../../components/Jobs";

import { createRef, useState, RefObject, ChangeEvent } from "react";
import { Chat, LoadingComponent, StatusSnackbar } from "../../components";

// Types
import type { JobRequest, RevisionFile } from "@lexgo/types/Jobs";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import { pdfjs } from "react-pdf";
import { getAuthHeaders } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type LocalRevisionFile = {
	name: string;
	file: Blob;
	previewImage?: Blob;
};
const RevisionModal = ({ open, onClose, onRevision }: { open: boolean; onClose: () => void; onRevision: (revision: LocalRevisionFile) => void }) => {
	const [status, setStatus] = useState<{ error?: string; success?: string }>({});
	const [revisionFile, setRevisionFile] = useState<LocalRevisionFile>();

	const canvasRef = createRef<HTMLCanvasElement>();

	const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files === null) return;

		const file = event.target.files[0];

		let previewImage: Blob | null | undefined = undefined;

		if (file.type === "application/pdf") {
			const document = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
			const page = await document.getPage(1);
			const viewport = page.getViewport({ scale: 1 });

			const canvas = canvasRef.current!;

			canvas.height = viewport.height;
			canvas.width = viewport.width;

			const context = canvas.getContext("2d")!;

			await page.render({ canvasContext: context, viewport }).promise;

			previewImage = await new Promise((res) => canvas.toBlob(res, "image/png"));

			if (previewImage === null) return setStatus({ error: "Could not read pdf!" });
		}
		if (file.type.startsWith("image")) previewImage = file;

		const name = file.name;

		setRevisionFile({ file, name, previewImage });
	};

	const uploadRef: RefObject<HTMLInputElement> = createRef();
	const showUploadPrompt = () => uploadRef.current!.click();

	if (!open && revisionFile !== undefined) setRevisionFile(undefined);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (revisionFile === undefined) return setStatus({ error: "Cannot create a new revision without a file" });
		onRevision(revisionFile);
		onClose();
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				style={{ padding: 25, borderRadius: 10 }}
				sx={{
					bgcolor: "background.paper",
				}}
			>
				<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({})} />
				<input ref={uploadRef} accept=".pdf" type="file" style={{ display: "none" }} onChange={onUpload.bind(this)} />
				<canvas ref={canvasRef} style={{ display: "none" }} />
				<Grid container direction="row">
					<Grid item>
						<Typography variant="h5">Upload a new revision</Typography>
					</Grid>
					<Grid item style={{ marginLeft: "auto" }}>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				<form onSubmit={onSubmit}>
					<Grid container spacing={3} direction="column" justifyContent="center">
						<Grid item>
							<TextField style={{ opacity: 0, position: "absolute", marginTop: -20 }} value={revisionFile?.name || ""} required />
							<Button variant="contained" startIcon={<UploadFileIcon />} onClick={showUploadPrompt}>
								Upload File
							</Button>
						</Grid>

						{revisionFile && (
							<>
								{revisionFile.previewImage ? (
									<Grid item>
										File Preview:
										<div style={{ maxHeight: 256, maxWidth: 900, overflow: "scroll", border: "1px solid lightgrey" }}>
											<div style={{ width: "auto" }}>
												<img src={URL.createObjectURL(revisionFile.previewImage!)} />
											</div>
										</div>
									</Grid>
								) : (
									<Grid item>Preview's of this file type are not supported</Grid>
								)}
								<Grid item>
									<Button
										type="submit"
										variant="contained"
										color="secondary"
										style={{ color: revisionFile === undefined ? "grey" : undefined }}
										size="large"
										disabled={revisionFile === undefined}
									>
										Submit Revision
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				</form>
			</Box>
		</Modal>
	);
};

export const JobInfo = (props: { job: JobRequest; onClose: () => void }) => {
	const { onClose } = props;
	const [job, setJob] = useState(props.job);
	const [showRevisionModal, setShowRevisionModal] = useState(false);

	const [status, setStatus] = useState<{ error?: string; success?: string; loading: boolean }>({ loading: false });

	const auth0 = useAuth0();

	const [chatOpen, setChatOpen] = useState(false);

	const onRevisionUpload = async (revisionFile: LocalRevisionFile) => {
		setShowRevisionModal(false);
		setStatus({ loading: true });

		let newRevisionFile: RevisionFile = {
			id: "",
			name: revisionFile.name,
		};

		const headers = await getAuthHeaders(auth0);

		if (revisionFile.previewImage !== undefined) {
			const imageUploadRes = await fetch("https://api.lexgo.co.nz/v1/image", {
				headers,
			});
			if (!imageUploadRes.ok) return setStatus({ loading: false, error: await imageUploadRes.text() });
			const { uploadURL } = await imageUploadRes.json();

			const formData = new FormData();
			formData.append("file", new File([revisionFile.previewImage], revisionFile.name));
			const uploadResult = await fetch(uploadURL, {
				method: "POST",
				body: formData,
			});

			if (!uploadResult.ok) return setStatus({ loading: false, error: await uploadResult.text() });

			newRevisionFile.previewImageId = (await uploadResult.json()).result.id;
		}

		newRevisionFile.id = await fetch("https://api.lexgo.co.nz/v1/files", {
			method: "POST",
			body: await revisionFile.file.arrayBuffer(),
			headers,
		}).then((res) => res.text());

		const updatedJob: JobRequest = await fetch(`https://api.lexgo.co.nz/v1/jobs/revisions`, {
			method: "POST",
			body: JSON.stringify({
				jobId: job.id,
				newRevisionFile,
			}),
			headers,
		}).then((res) => res.json());

		setJob(updatedJob);
		setStatus({ loading: false, success: "Added revision!" });
	};

	return (
		<Card elevation={12} style={{ width: 400, borderRadius: 2 }}>
			<JobImage image={job.listing.image} />
			<CardContent>
				<Grid container spacing={2} direction="column" justifyContent="center">
					<JobProperties job={job} />
					{status.loading ? (
						<LoadingComponent />
					) : (
						<Grid item>
							<Typography variant="h5">Deliverys</Typography>

							{job.revisions.length === 0 ? <Typography variant="body2">You have not provided any revisions yet.</Typography> : <JobRevisions job={job} />}
							<Button style={{ marginTop: 10 }} variant="outlined" onClick={() => setShowRevisionModal(true)} color="error">
								Add Revision
							</Button>
						</Grid>
					)}
				</Grid>
			</CardContent>
			{!status.loading && (
				<>
					<Divider />
					<CardActions>
						<Tooltip title="Close job details">
							<Button style={{ color: "black" }} onClick={onClose}>
								Close
							</Button>
						</Tooltip>
						<Tooltip title="Open the chat for this job">
							<Button color="info" size="small" onClick={() => setChatOpen(true)}>
								Buyer Chat
							</Button>
						</Tooltip>
					</CardActions>
				</>
			)}
			{chatOpen && <Chat open={chatOpen} onClose={() => setChatOpen(false)} jobId={job.id} userId={job.sellerId} isSeller />}
			<RevisionModal open={showRevisionModal} onClose={() => setShowRevisionModal(false)} onRevision={onRevisionUpload} />
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ loading: status.loading })} />
		</Card>
	);
};
