import { useState, useEffect } from "react";

import { imageEndpoint } from "@lexgo/types/constants";
import { Avatar, CircularProgress } from "@mui/material";

import { fetchSellerProfile } from "../../pages/helpers";

// Types
import { SellerProfile } from "@lexgo/types/Seller";

type OnClick = React.MouseEventHandler<HTMLButtonElement>;

interface IdProps {
	id: string;
	sellerProfile?: undefined;
}
interface ProfileProps {
	id?: undefined;
	sellerProfile: SellerProfile;
}

type SellerProfileProps = ProfileProps | IdProps;

export const SellerAvatar = ({ id, sellerProfile }: SellerProfileProps) => {
	const [profile, setSellerProfile] = useState<SellerProfile | undefined>(sellerProfile);

	useEffect(() => {
		if (sellerProfile === undefined) {
			fetchSellerProfile(id).then((sellerRes) => {
				if (sellerRes.err !== undefined || sellerRes === undefined) {
					console.error(sellerRes.err || "Seller profile not found");
					return;
				}
				setSellerProfile(sellerRes._);
			});
		}
	}, [id, sellerProfile]);
	if (profile === undefined) return <CircularProgress style={{ width: 32, height: 32 }} />;
	return <Avatar style={{ width: 32, height: 32 }} alt={profile.name} src={profile.image ? `${imageEndpoint}/${profile.image}/256` : "_"} />;
};
