import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, createElement } from "react";
import { LoadingPage } from ".";

interface ProtectedRouteV2Props {
	children: React.ComponentType<any>;
}

// This component is designed to not trigger duplicate renders when child components change.
// For more information please refer to this documentation:
// https://v5.reactrouter.com/web/api/Route/component
// https://github.com/auth0/auth0-react/issues/226
export const ProtectedRouteV2 = ({ children }: ProtectedRouteV2Props): JSX.Element => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

	useEffect(() => {
		if (isLoading || isAuthenticated) {
			return;
		}

		loginWithRedirect({
			appState: {
				returnTo: `${window.location.pathname}${window.location.search}`,
			},
		});
	}, [isLoading, isAuthenticated, loginWithRedirect]);

	return isAuthenticated ? createElement(children, {}) : <LoadingPage />;
};
