import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = { title: string; description: string; onYes: () => void } & React.ComponentProps<typeof Button>;
export const ConfirmPrompt = (props: Props) => {
	const [open, setOpen] = React.useState(false);

	const { title, description, onYes } = props;

	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleYes = () => {
		handleClose();
		onYes();
	};

	return (
		<>
			<Button onClick={handleClickOpen} {...props} />
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="success" onClick={handleYes}>
						Yes
					</Button>
					<Button color="error" onClick={handleClose} autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
