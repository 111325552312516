import { Box, Button, Card, CardContent, Divider, Modal, TextField, Typography } from "@mui/material";
import { useState, KeyboardEvent, useEffect } from "react";
import Draggable from "react-draggable";

import useWebSocket from "react-use-websocket";
import { getAuthHeaders } from "../pages/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { SellerAvatar } from ".";

// Icons
import SendIcon from "@mui/icons-material/Send";

// Types
import type { ChatMessage } from "@lexgo/types/Chat";

type Props = {
	open: boolean;
	onClose: () => void;
	jobId: string;
	userId: string;
	isSeller?: true;
};

export const Chat = ({ open, onClose, jobId, userId, isSeller }: Props) => {
	const auth0 = useAuth0();

	const [messages, setMessages] = useState<ChatMessage[]>([]);

	const [newMessage, setNewMessage] = useState("");
	const [chatUrl, setChatUrl] = useState("");

	const getChatUrl = async () => {
		const { Authorization } = await getAuthHeaders(auth0);
		const url = new URL("wss://api.lexgo.co.nz/v1/chat");
		url.searchParams.append("Authorization", Authorization);
		url.searchParams.append("jobId", jobId);
		return url.href;
	};

	useEffect(() => {
		getChatUrl().then(setChatUrl);
	}, [jobId]);

	const { sendMessage } = useWebSocket(
		chatUrl,
		{
			onOpen: () => setMessages([]),
			onMessage: (e) => {
				const data = JSON.parse(e.data);
				if (Array.isArray(data)) setMessages(data);
				else setMessages([...messages, data].sort((a, b) => a.timestamp - b.timestamp));
			},
		},
		chatUrl !== ""
	);

	const onEnter = (e: KeyboardEvent<HTMLDivElement>) => e.key === "Enter" && send();

	const send = () => {
		sendMessage(newMessage);
		setNewMessage("");
	};

	return (
		<Draggable>
			<Modal hideBackdrop open={open} onClose={onClose} style={{ width: 512, top: "20vh", left: "40vw" }}>
				<Card raised style={{ padding: 10, height: 600 }}>
					<CardContent>
						<Button variant="outlined" style={{ marginLeft: 380, position: "absolute" }} onClick={onClose}>
							Close
						</Button>
						<Typography variant="h4" style={{ textAlign: "center", marginBottom: 10 }} color="black">
							{isSeller ? "Buyer Chat" : "Seller Chat"}
						</Typography>
						<Divider />
						<br />
						<Box style={{ overflow: "scroll", height: 400 }}>
							{messages.map((message, i) => (
								<MessageBubble key={i} message={message} own={message.senderId === userId} isSeller={isSeller} />
							))}
						</Box>
						<TextField
							style={{ width: "100%" }}
							value={newMessage}
							onKeyPress={onEnter}
							onChange={(e) => setNewMessage(e.target.value)}
							label="New message"
							InputProps={{
								endAdornment: (
									<Button variant="contained" color="info" disabled={newMessage === ""} endIcon={<SendIcon />} onClick={send}>
										Send
									</Button>
								),
							}}
						/>
					</CardContent>
				</Card>
			</Modal>
		</Draggable>
	);
};

const MessageBubble = ({ message, own, isSeller }: { message: ChatMessage; own: boolean; isSeller?: boolean }) => {
	return (
		<div
			style={{
				margin: 0,
				marginLeft: own ? "auto" : undefined,
				width: "fit-content",
			}}
		>
			{isSeller && !own && (
				<span style={{ display: "inline-block", verticalAlign: "super", marginRight: 10 }}>
					<SellerAvatar id={message.senderId} />
				</span>
			)}
			<Card
				style={{
					display: "inline-block",
					padding: 10,
					backgroundColor: own ? "#5ab1fa" : undefined,
					color: own ? "white" : undefined,
					borderRadius: 15,
					borderTopRightRadius: own ? 0 : 15,
					borderTopLeftRadius: own ? 15 : 0,
				}}
			>
				{message.message}
			</Card>
		</div>
	);
};
