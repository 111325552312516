import { CardActionArea, CardMedia, CardContent, Typography, CardActions, Grid, Button, Tooltip } from "@mui/material";
import { useState } from "react";
import { HoverCard, MissingImage, StatusChips } from "../../components";
import { JobInfo } from "./JobInfo";

import { imageEndpoint } from "@lexgo/types/constants";

// Types
import type { JobRequest } from "@lexgo/types/Jobs";

export const ActiveJobCard = ({ job }: { job: JobRequest }) => {
	const [open, setOpen] = useState(false);

	const { listing } = job;
	if (open) return <JobInfo job={job} onClose={() => setOpen(false)} />;
	return (
		<HoverCard style={{ width: 250, borderRadius: 2 }}>
			<Tooltip title="View job details">
				<CardActionArea onClick={() => setOpen(true)}>
					{listing.image ? (
						<CardMedia component="img" height="140" image={`${imageEndpoint}/${listing.image}/256`} />
					) : (
						<MissingImage style={{ height: 150, width: "100%", backgroundColor: "main" }} />
					)}
					<CardContent>
						<Typography gutterBottom variant="h5" component="div">
							{listing.name}
							<br />
							<Typography variant="body2" component="span" color="text.secondary">
								Bought: {new Date(job.requestedAt).toDateString()}
							</Typography>
						</Typography>
					</CardContent>
				</CardActionArea>
			</Tooltip>
			<CardActions style={{ padding: 10 }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
					<Grid item>
						<Tooltip title="View job details">
							<Button style={{ color: "green" }} size="small" onClick={() => setOpen(true)}>
								Open
							</Button>
						</Tooltip>
					</Grid>
					<Grid item>{StatusChips[job.status]}</Grid>
				</Grid>
			</CardActions>
		</HoverCard>
	);
};
