import { Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import MailchimpSubscribe, { FormHooks, EmailFormFields } from "react-mailchimp-subscribe";
import { LoadingComponent } from "../../../components";

const CustomForm = ({ status, message, subscribe: submit }: FormHooks<EmailFormFields>) => {
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [role, setRole] = useState("");
	const [desc, setDesc] = useState("");

	useEffect(() => {
		if (status === "success") clearFields();
	}, [status]);

	const clearFields = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setDesc("");
		setRole("");
	};

	const toStr = (s: string | Error | null) => (s ? s.toString() : "");

	if (status === "sending") return <LoadingComponent />;

	return (
		<>
			{status === "error" && <div dangerouslySetInnerHTML={{ __html: toStr(message) }} />}
			{status === "success" && <div dangerouslySetInnerHTML={{ __html: toStr(message) }} />}

			{status !== "success" ? (
				<>
					<TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" required autoFocus />
					<TextField value={lastName} onChange={(e) => setLastName(e.target.value)} label="Last Name" required style={{ marginLeft: 10 }} />
					<br />
					<br />
					<TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Email" required />
					<TextField value={role} onChange={(e) => setRole(e.target.value)} label="Role" required style={{ marginLeft: 10 }} />
					<br />
					<br />
					<TextField
						value={desc}
						onChange={(e) => setDesc(e.target.value)}
						multiline
						minRows={3}
						label="What do you hope to get out of LexGo?"
						style={{ width: "100%" }}
					/>
					<br />
					<br />
					<Button
						variant="contained"
						color="secondary"
						size="large"
						onClick={() => {
							if (email !== "" && email.includes("@") && firstName !== "" && lastName !== "" && role !== "") {
								submit({
									EMAIL: email,
									FIRST_NAME: firstName,
									LAST_NAME: lastName,
									ROLE: role,
									DESC: desc,
								} as EmailFormFields);
							}
						}}
					>
						Submit
					</Button>
				</>
			) : null}
		</>
	);
};

export const NewSellerMailchimpForm = () => {
	const REACT_APP_MAILCHIMP_U = "74b2680420eeb88bbd253f717";
	const REACT_APP_MAILCHIMP_ID = "d408ee0500";
	const url = `https://lexgo.us1.list-manage.com/subscribe/post?u=${REACT_APP_MAILCHIMP_U}&id=${REACT_APP_MAILCHIMP_ID}`;

	return (
		<MailchimpSubscribe url={url} render={({ subscribe, status, message }) => <CustomForm status={status as any} message={message} subscribe={subscribe} />} />
	);
};
