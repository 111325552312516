import { Grid, Typography } from "@mui/material";
import { Header, Footer } from "../../components";

export const PrivacyPolicy = () => (
	<>
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20 }}>
			<Grid item>
				<Header />
			</Grid>
			<br />
			<br />
			<br />
			<Grid item>
				<Grid container justifyContent="center" spacing={5} style={{ maxWidth: 1200 }}>
					<Grid item>
						<Typography variant="h1">Privacy Policy</Typography>
						<Typography variant="body2">Last updated: 30 March 2022</Typography>
						<br />
						<br />
						LexGo, (“we,” “our,” or “us”) provides this privacy policy to describe how we collect, use, and share the information of individuals who visit our website
						at lexgo.co.nz (the “Website”). We are committed to ensuring we are compliant with relevant privacy legislations, including the New Zealand privacy Act
						2020.
						<br />
						<br />
						We know that your personal information is important to you and we are committed to ensuring it is kept secure and used only for the purposes described
						below.
						<br />
						<br />
						Below you will find a short summary of how and why we collect and use your data, who we share it with, and what we are doing to make sure your data is
						safe and secure.
						<br />
						<br />
						<br />
						<Typography variant="h3">WHAT DO WE DO?</Typography>
						We operate an online marketplace facilitating the creation, sale and purchase of documents.
						<br />
						<br />
						<br />
						<Typography variant="h3">WHAT INFORMATION DO WE COLLECT AND HOW IS IT USED?</Typography>
						The information that we may collect about you broadly falls into two categories:
						<br />
						<br />
						1.) Information that you voluntarily provide
						<br />
						<br />
						We may ask you to provide personal information when you visit our Website, order forms and third- party websites where our advertisements are displayed,
						in order to provide our services to you. This includes your contact details such as your name and email address.
						<br />
						<br />
						We use this information to respond to your queries. We also use this information to personalize and improve your experience of our services, Website, and
						communications with you, and for advertising and marketing purposes, including sending you offers from selected partners.
						<br />
						<br />
						2.) Information that you consent to us collecting automatically
						<br />
						<br />
						When you visit our Website and order forms, we may collect certain information automatically from your device.
						<br />
						<br />
						The data we collect may include information like your IP address, device type, unique device identification numbers, browser-type, and other technical
						information. We may also collect information about how your device has interacted with our Website, including the pages accessed and links clicked. This
						data is collected using cookies and similar technology.
						<br />
						<br />
						We collect this information so we can better understand the visitors who come to our Website and to save the visitor’s work in progress. Using the
						information allows us to improve the quality and relevance of our Website and services.
						<br />
						<br />
						<br />
						<Typography variant="h3">WHO DO WE SHARE YOUR INFORMATION WITH?</Typography>
						We may disclose your personal information to the following categories of recipients:
						<br />
						<br />
						to companies who provide services to us, for example, enhancing the security of our Website, or processing personal information for the purposes described
						in this privacy policy or as described to you when we collect your personal information;
						<br />
						<br />
						to third-party service providers who provide additional data processing services, for example, analyzing data, providing marketing assistance, assisting
						with the processing of credit card payments, delivering your subscriptions, or personalizing and improving your experience with us;
						<br />
						<br />
						to companies and to third parties (including, data resellers), so that they can market products and services we think may be of interest to you;
						<br />
						<br />
						as part of a corporate sale, merger, or acquisition, or other transfer of all or part of our assets, including as part of a bankruptcy proceeding;
						<br />
						<br />
						to any competent law enforcement body, regulatory, government agency, or similar other third-party where we believe disclosure is necessary as a matter of
						applicable law or regulation, or to exercise, establish or defend our legal rights or the rights of third parties; or with your consent or as otherwise
						disclosed at the time of data collection or sharing.
						<br />
						<br />
						<br />
						<Typography variant="h3">YOUR RIGHT TO REQUEST INFORMATION</Typography>
						You may request a record of the information we hold about you at any time by emailing{" "}
						<a href="mailto:info@lexgo.co.nz?subject=Request LexGo Information">
							<b>info@lexgo.co.nz</b>
						</a>
						. You may correct, alter, add or remove information to ensure the information we hold is correct.
						<br />
						<br />
						<b>Digital advertising &amp; analytics</b>
						<br />
						<br />
						We may partner with ad networks and other ad serving providers (“Advertising Providers”) that serve ads on behalf of us and others on non-affiliated
						platforms. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information Advertising Providers collect
						about your use of the Website and other sites or apps over time, including information about relationships among different browsers and devices.
						<br />
						<br />
						We may also work with third-parties that collect data about your use of the Website and other sites or apps over time for non-advertising purposes. We use
						Google Analytics and other third-party services to improve the performance of the website and for analytics and marketing purposes. For more information
						about how Google Analytics collects and uses data when you use our Website, visit{" "}
						<a href="https://google.com/policies/privacy/partners">https://google.com/policies/privacy/partners</a>, and to opt out of Google Analytics, visit{" "}
						<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
						<br />
						<br />
						<br />
						<Typography variant="h3">COOKIES</Typography>
						Cookies are small files placed on your hard drive by an internet server when you visit a website. Some cookies can be used to identify your computer each
						time you visit a site.
						<br />
						<br />
						Our Website uses cookies to save user progress. This allows you to leave the site and come back and continue where you left off. We may also use cookies
						to track site usage, traffic patterns and to deliver ads for services we think will be of interest to you.
						<br />
						<br />
						Your browser may offer tools to limit the use of cookies or to delete cookies; however, if you use these tools, our Website may not function as well as
						intended.
						<br />
						<br />
						<br />
						<Typography variant="h3">THIRD-PARTY LINKS AND TOOLS</Typography>
						Our Website may include hyperlinks to other websites that are not operated or controlled by us. This privacy policy only applies to our Website and we are
						not responsible for the content or the privacy practices of third-party websites. You should review the privacy notices of other websites that you use to
						learn about their data practices.
						<br />
						<br />
						The Website may also include integrated social media tools or “plug-ins,” such as social networking tools offered by third parties. If you use these tools
						to share personal information or you otherwise interact with these features on the Website, these companies may collect information about you and may use
						and share such information in accordance with your account settings, including by sharing such information with the general public. Your interactions with
						third-party companies and your use of their features are governed by the privacy notices of the companies that provide those features. We encourage you to
						carefully read the privacy notices of any accounts you create and use.
						<br />
						<br />
						<br />
						<Typography variant="h3">UPDATES TO THIS PRIVACY POLICY</Typography>
						We may update this privacy policy from time to time in response to changing legal, technical or business developments. When we update our privacy policy,
						we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material
						privacy policy changes if and where this is required by applicable data protection laws. You can see when this privacy policy was last updated by checking
						the “last updated” date displayed at the top of this privacy policy. <br />
						<br />
						<br />
						<br />
						<Typography variant="h3">HOW TO CONTACT US</Typography>
						If you have any questions or concerns about our use of your personal information, please contact us via email info@lexgo.co.nz
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		<br />
		<br />
		<Footer />
	</>
);
