import { Grid, TextField, Typography, Toolbar, Autocomplete, InputAdornment } from "@mui/material";

import { ListingCard, Header, Footer } from "../../components";
import { Component } from "react";

// Import types
import type { Listing, OrderBy } from "@lexgo/types/Jobs";

// Icon
import SearchIcon from "@mui/icons-material/Search";
import { LoadingComponent } from "../../components";

const orderByOptions: { label: OrderBy }[] = [
	{
		label: "Name",
	},
	{
		label: "Newest",
	},
	{
		label: "Oldest",
	},
	{
		label: "Highest Price",
	},
	{
		label: "Lowest Price",
	},
];

export class Listings extends Component {
	state: {
		loading: boolean;
	} = { loading: false };

	timer?: NodeJS.Timer;
	listings: Listing[] = [];

	getListings = async (params?: { search?: string; orderBy?: OrderBy }) => {
		this.setState({ loading: true });
		const url = new URL(`https://api.lexgo.co.nz/v1/listings`);

		if (params !== undefined) {
			if (params.orderBy) url.searchParams.append("orderBy", params.orderBy);
			if (params.search && params.search !== "") url.searchParams.append("search", params.search);
		}

		const listings = await fetch(url.href)
			.then((res) => res.json())
			.catch((err) => undefined);
		if (listings === undefined) return;
		this.listings = listings;
		this.setState({ loading: false });
	};

	search = (search: string) => {
		if (this.timer !== undefined) clearTimeout(this.timer);
		this.timer = setTimeout(() => this.getListings({ search }), 250);
	};

	componentDidMount = this.getListings;

	render = () => (
		<>
			<Grid container direction="column" justifyContent="center" alignItems="center" spacing={8} style={{ paddingTop: 20 }}>
				<Grid item>
					<Header />
				</Grid>
				<Grid item>
					<Typography variant="h4" align="center">
						Browse Listings
					</Typography>
				</Grid>
				<Grid item>
					<Toolbar style={{ minWidth: "100%", backgroundColor: "primary", maxWidth: 1280 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
							<Grid item>
								<TextField
									label="Search for a listing"
									variant="outlined"
									style={{ width: 720 }}
									onChange={(e) => this.search(e.target.value)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item>
								<Autocomplete
									disablePortal
									autoComplete={false}
									options={orderByOptions}
									onChange={(e, value) => this.getListings({ orderBy: value?.label })}
									sx={{ width: 200 }}
									renderInput={(params) => <TextField {...params} label="Order By" />}
								/>
							</Grid>
						</Grid>
					</Toolbar>
				</Grid>
				<Grid item>
					{this.state.loading ? (
						<LoadingComponent />
					) : (
						<Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} style={{ maxWidth: 1280 }}>
							{this.listings.map((listing, key) => (
								<Grid item key={key}>
									<ListingCard listing={listing} />
								</Grid>
							))}
						</Grid>
					)}
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
			<br />
			<Footer />
		</>
	);
}
