import { Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ListingCard } from "../../components";

// Import types
import type { Listing } from "@lexgo/types/Jobs";
import { useNavigate } from "react-router-dom";

export const NewListings = () => {
	const [topListings, setTopListings] = useState<Listing[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		fetch(`https://api.lexgo.co.nz/v1/listings/top`)
			.then((res) => res.json())
			.then((res) => {
				if (res.err !== undefined) console.error(res);
				if (res?._ === undefined) return;
				setTopListings(res._);
			})
			.catch(console.error);
	}, []);
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
			<Grid item>
				<Typography variant="h4" style={{ fontWeight: "bold" }}>
					Popular Job Cards
					<Button style={{ marginLeft: 20 }} variant="contained" size="large" color="secondary" onClick={() => navigate("/listings")}>
						View All
					</Button>
				</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} style={{ width: 1000 }}>
					{topListings.map((listing, key) => (
						<Grid item key={key}>
							<ListingCard listing={listing} />
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
