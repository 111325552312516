import { Grid, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

import EditableListing from "./EditableListing";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchListings, getAuthHeaders } from "../../helpers";

// Types
import type { Listing, NewListing } from "@lexgo/types/Jobs";
import { StatusSnackbar } from "../../../components";
import ModalListing from "./ModalListing";

type ListingsType = Record<string, Listing>;

export const Listings = ({ showNewListingPrompt, closeNewListingPrompt }: { showNewListingPrompt: boolean; closeNewListingPrompt: () => void }) => {
	const [listings, setListings] = useState<ListingsType>({});
	const [status, setStatus] = useState<{ success?: string; error?: string; loading: boolean }>({ loading: false });

	const auth0 = useAuth0();

	useEffect(() => {
		fetchListings(auth0, true).then((listingsRes) => {
			if (listingsRes.err !== undefined) return console.error("Error fetching listings", listingsRes.err);

			const listings = listingsRes._;

			setListings(
				listings.reduce((listings, listing) => {
					listings[listing.id] = listing;
					return listings;
				}, {} as ListingsType)
			);
			setStatus({ loading: false });
		});
	}, [auth0]);

	const createListing = async (listing: NewListing) => {
		setStatus({ loading: true });
		closeNewListingPrompt();

		const imageUploadRes = await fetch("https://api.lexgo.co.nz/v1/image", {
			headers: await getAuthHeaders(auth0),
		});
		if (!imageUploadRes.ok) return setStatus({ loading: false, error: await imageUploadRes.text() });

		const completeListing = (await fetch(`https://api.lexgo.co.nz/v1/listings`, {
			method: "POST",
			body: JSON.stringify(listing),
			headers: await getAuthHeaders(auth0),
		}).then((res) => res.json())) as Listing;

		setStatus({
			loading: false,
			success: "Uploaded listing!",
		});
		setListings({
			...listings,
			[completeListing.id]: completeListing,
		});
	};

	return (
		<>
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ success: undefined, error: undefined, loading: status.loading })} />
			{status.loading ? <LinearProgress /> : null}
			{listings !== undefined ? (
				<Grid container direction="row" spacing={4}>
					{Object.values(listings).map((listing) => (
						<Grid item key={listing.id}>
							<EditableListing
								listing={listing}
								onDelete={(id) => {
									delete listings[id];
									setListings(listings);
								}}
							/>
						</Grid>
					))}
				</Grid>
			) : null}
			<ModalListing open={showNewListingPrompt} onClose={closeNewListingPrompt} onSubmit={createListing} />
		</>
	);
};
