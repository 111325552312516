import { useState, useEffect } from "react";

import { imageEndpoint } from "@lexgo/types/constants";
import { Button, Avatar, Typography, CircularProgress } from "@mui/material";

import { fetchSellerProfile } from "../../pages/helpers";

// Types
import { SellerProfile } from "@lexgo/types/Seller";

type OnClick = React.MouseEventHandler<HTMLButtonElement>;

type BaseProps = {
	onClick?: OnClick;
};

interface IdProps extends BaseProps {
	id: string;
	sellerProfile?: undefined;
}
interface ProfileProps extends BaseProps {
	id?: undefined;
	sellerProfile: SellerProfile;
}

type SellerProfileProps = ProfileProps | IdProps;

export const SellerProfileButton = ({ id, onClick, sellerProfile }: SellerProfileProps) => {
	const [profile, setSellerProfile] = useState<SellerProfile | undefined>(sellerProfile);

	useEffect(() => {
		if (sellerProfile === undefined) {
			fetchSellerProfile(id).then((sellerRes) => {
				if (sellerRes.err !== undefined || sellerRes === undefined) {
					console.error(sellerRes.err || "Seller profile not found");
					return;
				}
				setSellerProfile(sellerRes._);
			});
		}
	}, [id, sellerProfile]);
	if (profile === undefined) return <CircularProgress style={{ width: 32, height: 32 }} />;
	return (
		<Button style={{ textTransform: "none", borderRadius: 16 }} onClick={onClick}>
			<Avatar style={{ width: 32, height: 32, marginRight: 10 }} alt={profile.name} src={profile.image ? `${imageEndpoint}/${profile.image}/256` : "_"} />
			<Typography variant="body1" color="textPrimary">
				{profile.name}
			</Typography>
		</Button>
	);
};
