import { CircularProgress, Grid, Typography } from "@mui/material";

export const LoadingComponent = () => (
	<Grid container direction="column" alignItems="center" justifyContent="center">
		<Typography variant="h5">Loading</Typography>
		<CircularProgress />
	</Grid>
);

export const LoadingPage = () => (
	<Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
		<LoadingComponent />
	</Grid>
);
