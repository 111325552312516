import { Grid, Paper } from "@mui/material";

// Components
import { Header } from "..";
import { SellerProfileModule } from "./SellerProfileModule";
import { SellerListings } from "../../pages/Home/SellerProfile/SellerListings";
import { useNavigate } from "react-router-dom";

export const SellerPage = () => {
	const navigate = useNavigate();
	const columnWidth = "310px";
	const id = new URLSearchParams(window.location.search).get("id");
	if (id === null) {
		navigate("/");
		return <></>;
	}
	return (
		<>
			<Header />
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				style={{
					marginTop: 32,
					width: "100%",
				}}
			>
				<Grid item style={{ width: columnWidth }}>
					<Paper elevation={3} style={{ padding: "32px", height: "800px" }}>
						<SellerProfileModule id={id} />
					</Paper>
				</Grid>
				<Grid item style={{ width: "950px" }}>
					<Paper elevation={3} style={{ height: "800px", overflowX: "hidden", overflowY: "auto" }}>
						<div style={{ position: "relative" }}>
							<SellerListings id={id} />
						</div>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};
