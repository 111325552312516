import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Header, Footer, FakeListing } from "../../components";
import { NewListings } from "./NewListings";

const fakeListings: FakeListing[] = [
	{
		name: "Squarepants Star",
		description: "We will draft a bestie agreement",
		price: 69,
		image: "2ac35bc8-0399-4a8b-b112-bc5f6cc16a00",
		seller: {
			name: "Patrick Star",
			image: "fe4f4332-e845-4535-165e-8f0dbb1e3100",
		},
	},
	{
		name: "Doggy Law",
		description: "We will draft a pet sitting agreement",
		price: 50,
		image: "90e4ecb9-07ad-4fab-7125-4d7ab95c3100",
		seller: {
			name: "Scott Wang",
			image: "d7714f4c-8f44-4cba-0787-349525c3a700",
		},
	},
	{
		name: "Your Listing",
		description: "This could be your first listing",
		price: 99,
		image: "657505b1-45f5-4699-ad5f-e0269feaf100",
		seller: {
			name: "Your Name",
		},
	},
];

export const Home = () => {
	const navigate = useNavigate();
	return (
		<>
			<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20 }}>
				<Grid item>
					<Header />
				</Grid>
				<br />
				<br />
				<Grid item>
					<Card elevation={3}>
						<CardContent style={{ position: "relative", width: 1200, height: 600 }}>
							<CardMedia
								style={{ position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }}
								image="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/d77e9d14-08d6-49e8-03e4-a2486a6f8e00/1920"
							/>
							<Box style={{ width: 700, padding: 50, paddingTop: 100, position: "relative" }}>
								<Typography variant="h2" style={{ fontWeight: "bold" }} color="white">
									New Zealand's best marketplace for <br />
									legal solutions
								</Typography>
								<br />
								<Typography variant="h6" color="white" style={{ fontWeight: "normal" }} component="p">
									The fast & affordable way for bespoke contracts
								</Typography>
								<br />
								<br />
								<Button size="large" color="secondary" variant="contained" onClick={() => navigate("/listings")} style={{ marginRight: 40, borderRadius: 2 }}>
									Browse Contracts
								</Button>
							</Box>
						</CardContent>
					</Card>
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<Grid item>
					<NewListings />
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<iframe
					title="Draft with us video"
					src="https://player.vimeo.com/video/690135107?h=cdad337dcf&title=0&byline=0&portrait=0"
					width="640"
					height="360"
					allow="autoplay; fullscreen; picture-in-picture"
					style={{ border: "none" }}
				/>
				<br />
				<br />
				<br />
				<br />
				<Grid item>
					<Typography variant="h4" color="textSecondary" style={{ fontWeight: "bold" }}>
						Proudly supported by:
					</Typography>
				</Grid>
				<br />
				<Grid item>
					<Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
						<Grid item>
							{/* Sellar Bone */}
							<img src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/78c76122-cfe8-4a67-778b-6d4ea1d09c00/256" style={{ width: 256 }} />
						</Grid>
						<Grid item>
							{/* MetroLaw */}
							<img src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/d37a82f9-a6c3-43ca-5587-437ac214c700/256" style={{ width: 256 }} />
						</Grid>
						<Grid>
							{/* Duovorce */}
							<img src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/3c317570-d45d-4322-51db-91e0db325900/256" style={{ width: 256 }} />
						</Grid>
					</Grid>
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<Grid item>
					<Card elevation={3}>
						<CardContent style={{ position: "relative", width: 1200, height: 600 }}>
							<CardMedia
								style={{ position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }}
								image="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/b42a3415-f807-44a4-21c9-d7162dc03200/1920"
							/>
							<Box style={{ width: 500, padding: 50, paddingTop: 100, position: "relative" }}>
								<Typography variant="h4" style={{ fontWeight: "bold" }} color="#4e4e4e">
									Join the community of legal{" "}
									<Typography variant="h4" color="primary" style={{ fontWeight: "bold" }} component="span">
										content creators
									</Typography>
								</Typography>
								<br />
								<Typography variant="h6" style={{ fontWeight: "normal", width: 250 }} color="#4e4e4e" component="p">
									No advisory required, all experiences are welcome. <br />
									Contact us to get setup and start earning today.
								</Typography>
								<br />
								<br />
								<br />
								<Button variant="contained" size="large" color="secondary" onClick={() => navigate("/newSeller")}>
									Get Started
								</Button>
							</Box>
						</CardContent>
					</Card>
				</Grid>
				<br />
				<br />
				<br />
				<br />
			</Grid>
			<Footer />
		</>
	);
};
