import { createRef, Component, RefObject, InputHTMLAttributes } from "react";

import { IconButton } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

export class UploadWrapper extends Component<{
	src?: string;
	alt?: string;
	onUpload?: InputHTMLAttributes<HTMLInputElement>["onChange"];
	style?: React.CSSProperties;
	children?: React.ReactNode;
}> {
	private readonly uploadRef: RefObject<HTMLInputElement>;

	state: {
		hover: boolean;
	};
	constructor(props: any) {
		super(props);
		this.state = {
			hover: false,
		};

		this.uploadRef = createRef();
	}

	showUploadPrompt = () => this.uploadRef.current!.click();

	render() {
		return (
			<div onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
				<input ref={this.uploadRef} type="file" style={{ display: "none" }} onChange={this.props.onUpload} />
				{this.state.hover ? (
					<IconButton onClick={this.showUploadPrompt.bind(this)} style={{ position: "absolute", zIndex: 99999, ...this.props.style }}>
						<AddAPhotoIcon fontSize="large" />
					</IconButton>
				) : null}
				{this.props.children}
			</div>
		);
	}
}
