import { Grid, Toolbar, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const Footer = () => {
	const navigate = useNavigate();
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ background: "#F2F7FA", padding: 40, overflow: "hidden" }}>
			<Grid item>
				<Toolbar>
					<Grid container direction="row" alignItems="center" spacing={4}>
						<Grid item>
							<Button style={{ fontWeight: "bold" }} onClick={() => navigate("/ourStory")}>
								Our Story
							</Button>
						</Grid>
						<Grid item>
							<Button onClick={() => navigate("/privacyPolicy")} style={{ fontWeight: "bold" }}>
								Privacy Policy
							</Button>
						</Grid>
						<Grid item>
							<img src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/29fab79d-51c0-45e7-4d21-2580adfd0700/256" style={{ width: 200 }} />
						</Grid>

						<Grid item>
							<Button onClick={() => navigate("/termsOfUse")} style={{ fontWeight: "bold" }}>
								Terms of Use
							</Button>
						</Grid>
						<Grid item>
							<Button href="mailto:info@lexgo.co.nz" style={{ fontWeight: "bold" }}>
								Contact Us
							</Button>
						</Grid>
					</Grid>
				</Toolbar>
			</Grid>
		</Grid>
	);
};

export const RequestBespoke = () => {
	const navigate = useNavigate();
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
			<Grid item>
				<Typography variant="h6" style={{ fontWeight: "bold" }}>
					Cant find what you want?
				</Typography>
			</Grid>
			<Grid item>
				<Button style={{ borderRadius: 2 }} onClick={() => navigate("/requestBespoke")} variant="contained">
					Request bespoke
				</Button>
			</Grid>
		</Grid>
	);
};
