import { useEffect, useState } from "react";

import { Avatar, Divider, Grid, Typography } from "@mui/material";

import { fetchSellerProfile } from "../../pages/helpers";
import { imageEndpoint } from "@lexgo/types/constants";

// Components
import { StatusSnackbar, LoadingComponent } from "..";

// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

// Types
import type { SellerProfile } from "@lexgo/types/Seller";
const magicDivStyle: React.CSSProperties = {
	display: "flex",
	alignItems: "center",
	flexWrap: "wrap",
	marginBottom: 5,
};

export const SellerProfileModule = ({ id }: { id: string }) => {
	const [status, setStatus] = useState<{ error?: string; success?: string; loading: boolean }>({ loading: true });
	const [sellerProfile, setSellerProfile] = useState<SellerProfile>();

	useEffect(() => {
		fetchSellerProfile(id)
			.catch((err) => setStatus({ error: err.message, loading: status.loading }))
			.then((sellerRes) => {
				if (sellerRes === undefined) return setStatus({ error: "Cannot load profile: Failed to fetch profile information!", loading: status.loading });
				if (sellerRes.err !== undefined) return setStatus({ error: sellerRes.err, loading: status.loading });

				setStatus({ loading: false });
				setSellerProfile(sellerRes._);
			});
	}, [id]);

	if (status.loading || sellerProfile === undefined) return <LoadingComponent />;

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center">
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ success: undefined, error: undefined, loading: status.loading })} />
			<Grid item>
				<Avatar src={sellerProfile.image !== undefined ? `${imageEndpoint}/${sellerProfile.image}/256` : "_"} style={{ height: "128px", width: "128px" }} />
			</Grid>
			<br />
			<Grid item>
				<Typography variant="h5">{sellerProfile.name}</Typography>
			</Grid>
			<br />
			<Divider style={{ width: "100%" }}>Seller Info</Divider>
			<br />
			<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
				<Grid item style={{ maxHeight: 200, overflow: "scroll" }}>
					{sellerProfile.description}
				</Grid>
			</Grid>
			<br />

			<Grid item style={magicDivStyle}>
				<PersonIcon style={{ color: "grey" }} />
				<span style={{ marginLeft: 4 }}>Joined On</span>
			</Grid>
			<Grid item>
				<Typography variant="h5"> {new Date(sellerProfile.created).toDateString().split(" ").slice(1).join(" ")}</Typography>
			</Grid>
			<br />
			<br />

			<Grid item style={magicDivStyle}>
				<LocationOnIcon style={{ color: "grey" }} />
				<span style={{ marginLeft: 4 }}>Location</span>
			</Grid>
			<Grid item>
				<Typography variant="h5">{sellerProfile.location}</Typography>
			</Grid>
			<br />
			<br />
			<Grid item style={magicDivStyle}>
				<CardMembershipIcon style={{ color: "grey" }} />
				<span style={{ marginLeft: 4 }}>Role</span>
			</Grid>
			<Grid item>
				<Typography variant="h5">{sellerProfile.role}</Typography>
			</Grid>
		</Grid>
	);
};
