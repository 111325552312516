import { useAuth0 } from "@auth0/auth0-react";
import { Toolbar, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Header = () => {
	const navigate = useNavigate();
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20 }}>
			<Grid item>
				<Toolbar>
					<img
						src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/29fab79d-51c0-45e7-4d21-2580adfd0700/256"
						style={{ maxWidth: 200, cursor: "pointer" }}
						onClick={() => navigate("/")}
					/>
					<Button style={{ marginLeft: 100, fontWeight: "bold" }} onClick={() => navigate("/ourStory")} size="large">
						Our Story
					</Button>
					<Button style={{ marginLeft: 20, fontWeight: "bold" }} size="large">
						Personal
					</Button>
					<Button style={{ marginLeft: 20, fontWeight: "bold" }} size="large">
						Business
					</Button>
					<Button style={{ marginLeft: 20, fontWeight: "bold" }} size="large">
						Property
					</Button>
					<LoginSignup />
				</Toolbar>
			</Grid>
		</Grid>
	);
};

export const LoginSignup = () => {
	const auth0 = useAuth0();
	const navigate = useNavigate();
	if (!auth0.isAuthenticated)
		return (
			<Button color="secondary" variant="contained" style={{ borderRadius: 2, marginLeft: 100, fontWeight: "bold" }} onClick={() => navigate("/buyer")}>
				Login/Signup
			</Button>
		);
	return (
		<>
			<Button color="secondary" variant="contained" style={{ borderRadius: 2, marginLeft: 100, fontWeight: "bold" }} onClick={() => navigate("/buyer")}>
				Buyer Dashboard
			</Button>
			<Button color="info" variant="contained" style={{ borderRadius: 2, marginLeft: 20, fontWeight: "bold" }} onClick={() => navigate("/seller")}>
				Seller Dashboard
			</Button>
		</>
	);
};
