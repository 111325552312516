import type { SellerProfile } from "@lexgo/types/Seller";
import type { JobRequest, JobRevision, Listing, PendingJobRequest } from "@lexgo/types/Jobs";
import type { CachedResponse } from "@inrixia/cfworker-helpers";
import { useAuth0 } from "@auth0/auth0-react";

export const parseResponse = async (response: Response | Promise<Response>): Promise<false | string> => {
	response = await response;
	if (response.ok) return false;
	if (!response.ok) {
		try {
			return `${(await response.json().then((data) => data.err)) || ""}`;
		} catch (error) {
			return (error as Error).message;
		}
	}
	return "An unknown error occoured";
};

const sCache = new Map<string, SellerProfile>();

export async function fetchSellerProfile(id: string, noCache?: boolean): Promise<CachedResponse<SellerProfile>>;
export async function fetchSellerProfile(auth0: Auth0, noCache?: boolean): Promise<CachedResponse<SellerProfile>>;
export async function fetchSellerProfile(key: string | Auth0, noCache?: boolean): Promise<CachedResponse<SellerProfile>> {
	const usingHeaders = typeof key !== "string";

	const id = usingHeaders ? key.user?.sub : key;
	if (noCache !== true && id !== undefined && sCache.has(id)) {
		return { _: sCache.get(id)!, cached: true };
	}

	const url = new URL("https://api.lexgo.co.nz/v1/seller/profile");
	if (!usingHeaders) url.searchParams.append("id", key);
	if (noCache) url.searchParams.append("noCache", "");

	const sellerRes: CachedResponse<SellerProfile> = await fetch(url.href, {
		headers: usingHeaders ? await getAuthHeaders(key) : undefined,
	}).then((res) => res.json());

	if (id !== undefined && sellerRes.err === undefined) sCache.set(id, sellerRes._);

	return sellerRes;
}

type ListingReturn = Promise<CachedResponse<Listing>>;
type ListingsReturn = Promise<CachedResponse<Listing[]>>;

function _fetchListings(sellerId: string, options: { id: string; noCache?: boolean }): ListingReturn;
function _fetchListings(sellerId: string, options: { id?: undefined; noCache?: boolean }): ListingsReturn;
function _fetchListings(auth0: Auth0, options: { id: string; noCache?: boolean }): ListingReturn;
function _fetchListings(auth0: Auth0, options: { id?: undefined; noCache?: boolean }): ListingsReturn;
async function _fetchListings(key: string | Auth0, options: { id?: string; noCache?: boolean } = {}): Promise<CachedResponse<Listing[] | Listing>> {
	const usingHeaders = typeof key !== "string";
	const { id, noCache } = options;
	const url = new URL("https://api.lexgo.co.nz/v1/listings/byId");
	if (id !== undefined) url.searchParams.append("id", id);
	if (noCache) url.searchParams.append("noCache", "");
	if (!usingHeaders) url.searchParams.append("sellerId", key);

	const result = await fetch(url.href, {
		headers: usingHeaders ? await getAuthHeaders(key) : undefined,
	}).then((res) => res.json());

	return result;
}

export async function fetchListing(sellerId: string, listingId: string, noCache?: boolean): ListingReturn;
export async function fetchListing(auth0: Auth0, listingId: string, noCache?: boolean): ListingReturn;
export async function fetchListing(key: string | Auth0, listingId: string, noCache?: boolean): ListingReturn {
	return _fetchListings(key as any, { id: listingId, noCache });
}

export async function fetchListings(sellerId: string, noCache?: boolean): ListingsReturn;
export async function fetchListings(auth0: Auth0, noCache?: boolean): ListingsReturn;
export async function fetchListings(key: string | Auth0, noCache?: boolean): ListingsReturn {
	return _fetchListings(key as any, { noCache });
}

export const downloadFile = async (jobId: string, jobRevision: JobRevision, auth0: Auth0) => {
	const url = new URL("https://api.lexgo.co.nz/v1/jobs/revisions/file");
	url.searchParams.append("jobId", jobId);
	url.searchParams.append("revisionAt", jobRevision.submittedAt.toString());

	const result = await fetch(url.href, {
		headers: await getAuthHeaders(auth0),
	});

	if (!result.ok) throw new Error((await result.json()).err || "An unknown error occoured");

	const link = document.createElement("a");

	link.href = window.URL.createObjectURL(await result.blob());

	link.setAttribute("download", `${jobRevision.files[0].name}`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

type Auth0 = ReturnType<typeof useAuth0>;
export const getAuthHeaders = async (auth0: Auth0) => ({
	Authorization: `Bearer ${await auth0.getAccessTokenSilently()}`,
	Origin: window.location.host,
});

export const getJobs = async (auth0: Auth0, type: "buyer" | "seller"): Promise<JobRequest[]> =>
	fetch(`https://api.lexgo.co.nz/v1/jobs/${type}`, { headers: await getAuthHeaders(auth0) }).then((res) => res.json());

export const isPaymentPendingJob = (job: JobRequest): job is PendingJobRequest => job.status === "paymentPending";
export const isCompletedJob = (job: JobRequest) => job.status === "completed";
export const isCancelledJob = (job: JobRequest) => job.status === "cancelled";
export const isActiveJob = (job: JobRequest) => job.status === "changesRequested" || job.status === "newRevision" || job.status === "paid";
