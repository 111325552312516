import {
	IconButton,
	Card,
	Box,
	CardContent,
	CardActions,
	Tooltip,
	LinearProgress,
	Typography,
	Button,
	Divider,
	CardMedia,
	CardActionArea,
} from "@mui/material";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { Component, InputHTMLAttributes } from "react";

// Components
import { ListingPropertiesForm, OnListingPropsChanged } from "./ListingPropertiesForm";
import { UploadWrapper, MissingImage, StatusSnackbar } from "../../../components";

// Icons
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

// Helpers
import { getAuthHeaders, parseResponse } from "../../helpers";
import { imageEndpoint } from "@lexgo/types/constants";

// Types
import type { Listing } from "@lexgo/types/Jobs";

type Props = { listing: Listing; onDelete: (id: string) => void } & WithAuth0Props;
type State = {
	listing: Listing;
	editedListing: Listing;
	newImage?: Blob;
	editing: boolean;
	loading: boolean;
	success?: string;
	error?: string;
	raised: boolean;
	invalid: boolean;
};

export default withAuth0(
	class EditableListing extends Component<Props> {
		state: State;
		constructor(props: Props) {
			super(props);
			this.state = {
				listing: props.listing,
				editedListing: props.listing,
				editing: false,
				loading: false,
				raised: false,
				invalid: false,
			};
		}

		onDelete = async () => {
			this.setState({ loading: true });
			const headers = await getAuthHeaders(this.props.auth0);
			const listingErr = fetch(`https://api.lexgo.co.nz/v1/listings?id=${this.state.listing.id}`, {
				headers,
				method: "DELETE",
			}).then(parseResponse);

			if (!listingErr) {
				this.setState({ success: "Listing deleted", loading: false });
				this.props.onDelete(this.state.listing.id);
			} else {
				// If there were errors then display them
				this.setState({
					error: `Error deleting listing: ${listingErr}`,
					loading: false,
				});
			}
		};

		saveChanges = async () => {
			this.setState({ loading: true });
			const headers = await getAuthHeaders(this.props.auth0);

			let editedListing = this.state.editedListing;

			if (this.state.newImage !== undefined) {
				const imageUploadRes = await fetch("https://api.lexgo.co.nz/v1/image", {
					headers,
				});
				if (!imageUploadRes.ok) return this.setState({ loading: false, error: await imageUploadRes.text() });
				const { uploadURL } = await imageUploadRes.json();

				const formData = new FormData();
				formData.append("file", this.state.newImage);
				const uploadResult = await fetch(uploadURL, {
					method: "POST",
					body: formData,
				});

				if (!uploadResult.ok) return this.setState({ loading: false, error: await uploadResult.text() });

				const { result } = await uploadResult.json();

				editedListing = { ...this.state.editedListing, image: result.id };

				this.setState({ success: "Uploaded listing image!", editedListing });
			}

			const updateErr = await fetch(`https://api.lexgo.co.nz/v1/listings`, {
				headers,
				method: "PUT",
				body: JSON.stringify(editedListing),
			}).then(parseResponse);
			if (updateErr) {
				// If a new image was uploaded delete it
				if (this.state.newImage !== undefined && editedListing.image !== undefined) {
					const url = new URL("https://api.lexgo.co.nz/v1/image");
					url.searchParams.append("id", editedListing.image);
					await fetch(url.href, {
						headers,
						method: "DELETE",
					});
				}
				this.setState({
					error: `Failed to delete listing! ${updateErr}`,
					loading: false,
				});
			} else this.setState({ success: "Listing edited", loading: false, editing: false, raised: false, listing: editedListing });
		};

		onImageUpload: InputHTMLAttributes<HTMLInputElement>["onChange"] = async (event) => {
			const file = event.target.files ? event.target.files[0] : null;

			if (file === null) return this.setState({ error: "Only images are supported" });
			// Make sure uploaded file is a image
			if (!file.type.includes("image")) return this.setState({ error: "Only images are supported" });

			this.setState({ newImage: file });
		};

		onMouseOver = () => this.setState({ raised: true });
		onMouseOut = () => this.setState({ raised: false });

		onListingPropertyChange: OnListingPropsChanged = ({ updatedListing, invalid }) => this.setState({ editedListing: updatedListing, invalid });

		render() {
			const editedListing = this.state.editedListing;
			let editedListingImage;

			if (this.state.newImage !== undefined)
				editedListingImage = (
					<img src={window.URL.createObjectURL(this.state.newImage)} style={{ borderRadius: 2, height: "100%", width: "100%", objectFit: "cover" }} />
				);
			else if (editedListing.image !== undefined)
				editedListingImage = (
					<img src={`${imageEndpoint}/${editedListing.image}/256`} style={{ borderRadius: 2, height: "100%", width: "100%", objectFit: "cover" }} />
				);
			else editedListingImage = <MissingImage style={{ width: "100%", height: 150 }} />;
			return (
				<>
					<StatusSnackbar success={this.state.success} error={this.state.error} onClose={() => this.setState({ success: undefined, error: undefined })} />
					{this.state.editing ? (
						<Card elevation={12} style={{ width: 400, borderRadius: 2 }}>
							<UploadWrapper style={{ height: 150, width: 400, borderRadius: 4, color: "white" }} onUpload={this.onImageUpload!.bind(this)}>
								<Box borderBottom={0} borderRadius={1} style={{ backgroundColor: "rgba(0,0,0, 0.2)", height: 150, width: "100%" }}>
									{editedListingImage}
								</Box>
							</UploadWrapper>
							{this.state.loading ? <LinearProgress /> : null}
							<CardContent>
								<ListingPropertiesForm listing={editedListing} onChange={this.onListingPropertyChange.bind(this)} />
							</CardContent>
							<Divider />
							<CardActions>
								<Tooltip title="Discard Changes">
									<Button
										style={{ color: "rgba(255, 48, 25, 0.6)", borderRadius: 2 }}
										size="small"
										onClick={() => this.setState({ editing: false, raised: false, editedListing: this.state.listing })}
									>
										Discard
									</Button>
								</Tooltip>
								<Tooltip title="Save">
									<Button
										style={{ color: this.state.invalid ? "grey" : "green", borderRadius: 2 }}
										size="small"
										onClick={this.saveChanges.bind(this)}
										disabled={this.state.invalid}
									>
										Save
									</Button>
								</Tooltip>
							</CardActions>
						</Card>
					) : (
						<Card elevation={this.state.raised ? 12 : 3} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} style={{ width: "250px", borderRadius: 2 }}>
							<CardActionArea>
								{editedListing.image ? (
									<CardMedia component="img" height={150} image={`${imageEndpoint}/${editedListing.image}/256`} />
								) : (
									<MissingImage style={{ height: 150, width: "100%" }} />
								)}
								{this.state.loading ? <LinearProgress /> : null}
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{this.state.listing.name}
									</Typography>
									<Typography variant="body2" color="text.secondary" style={{ height: 100, overflow: "scroll", whiteSpace: "pre-line" }}>
										{this.state.listing.description}
									</Typography>
								</CardContent>
								<CardActions style={{ padding: 10, paddingLeft: 20 }}>
									<Button color="secondary" style={{ marginLeft: "auto" }}>{`$${this.state.listing.price}`}</Button>
								</CardActions>
							</CardActionArea>
							<Divider />
							<CardActions>
								{/* <Tooltip title="Delete">
									<IconButton style={{ color: "rgba(255, 48, 25, 0.6)", borderRadius: 2 }} href="" size="small" onClick={this.onDelete.bind(this)}>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Tooltip> */}
								<Tooltip title="Edit">
									<IconButton style={{ borderRadius: 2 }} size="small" onClick={() => this.setState({ editing: true })}>
										<EditIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</CardActions>
						</Card>
					)}
				</>
			);
		}
	}
);
