import { Route, Routes, useLocation } from "react-router";

// Pages
import { Home } from "./pages/Home";
import { Seller } from "./pages/Seller";
import { Buyer } from "./pages/Buyer";
import { Listings } from "./pages/Listings";

import { Error404, ProtectedRouteV2 } from "./components";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { blueGrey, amber } from "@mui/material/colors";
import { StripeResponse } from "./pages/StripeResponse";
import { OurStory } from "./pages/Home/OurStory";
import { PrivacyPolicy } from "./pages/Home/PrivacyPolicy";
import { TermsOfUse } from "./pages/Home/TermsOfUse";
import { SellerPage } from "./components/SellerProfile/SellerProfile";
import { NewSeller } from "./pages/Home/NewSeller";

const App = () => {
	const path = useLocation().pathname;
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)") && path !== "/";

	const theme = createTheme({
		palette: {
			// Hard code light mode for now
			mode: prefersDarkMode && false ? "dark" : "light",
			primary: blueGrey,
			secondary: amber,
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						"::-webkit-scrollbar, & *::-webkit-scrollbar": {
							width: "5px",
						},

						/* Track */
						"::-webkit-scrollbar-track": {
							background: "#e5e5e5",
						},

						/* Handle */
						"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
							background: "#888",
						},

						/* Handle on hover */
						"&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
							background: "#555",
						},
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/ourStory" element={<OurStory />} />
				<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
				<Route path="/termsOfUse" element={<TermsOfUse />} />
				{/* <Route path="/draftWithUs" element={<DraftWithUs />} />
				<Route path="/requestBespoke" element={<RequestBespoke />} /> */}
				<Route path="/listings" element={<Listings />} />
				<Route path="/sellerProfile" element={<SellerPage />} />
				<Route path="/seller" element={<ProtectedRouteV2 children={Seller} />} />
				<Route path="/buyer" element={<ProtectedRouteV2 children={Buyer} />} />
				<Route path="/checkoutCancel" element={<StripeResponse whatHappened={"checkoutCancel"} />} />
				<Route path="/checkoutSuccess" element={<StripeResponse whatHappened={"checkoutSuccess"} />} />
				<Route path="/newSeller" element={<NewSeller />} />
				<Route element={<Error404 />} />
			</Routes>
		</ThemeProvider>
	);
};

export default App;
