import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	CardActions,
	Button,
	Grid,
	CardActionArea,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";
import { fetchSellerProfile, getAuthHeaders } from "../../pages/helpers";
import { imageEndpoint } from "@lexgo/types/constants";

import { useEffect, useState } from "react";
import { MissingImage } from "../MissingImage";

import { useAuth0 } from "@auth0/auth0-react";

import { SellerPreview, SellerProfileButton } from "../SellerProfile";

// Types
import type { Listing } from "@lexgo/types/Jobs";
import type { SellerProfile } from "@lexgo/types/Seller";
import { useNavigate } from "react-router";
import { StatusSnackbar } from "../StatusSnackbar";

export const ListingCard = ({ listing }: { listing: Listing }) => {
	const [sellerProfile, setSellerProfile] = useState<SellerProfile>();
	const [raised, setRaised] = useState(false);

	const [notSignedInPrompt, setNotSignedInPrompt] = useState(false);
	const [enterQuestionPrompt, setEnterQuestionPrompt] = useState(false);

	const [answers, setAnswers] = useState<string[]>([]);

	const [status, setStatus] = useState<{ error?: string; success?: string }>({});

	useEffect(() => {
		fetchSellerProfile(listing.sellerId, true).then((sellerRes) => {
			if (sellerRes.err !== undefined) return console.error(sellerRes.err);
			setSellerProfile(sellerRes._);
		});
	}, [listing.sellerId]);

	const onMouseOver = () => setRaised(true);
	const onMouseOut = () => setRaised(false);

	const auth0 = useAuth0();
	const navigate = useNavigate();

	const checkout = async () => {
		setNotSignedInPrompt(false);
		setEnterQuestionPrompt(false);

		if (!auth0.isAuthenticated) await auth0.loginWithPopup();

		if (!auth0.isAuthenticated) return setNotSignedInPrompt(true);

		if (answers.length !== listing.questions.length) return setEnterQuestionPrompt(true);

		const url = new URL("https://api.lexgo.co.nz/v1/stripe/checkout");

		if (listing.id === undefined) throw new Error("Missing listing id");
		if (listing.sellerId === undefined) throw new Error("Missing seller id");

		url.searchParams.set("listingId", listing.id);
		url.searchParams.set("sellerId", listing.sellerId);
		url.searchParams.set("qAnswers", answers.join(","));

		const beginCheckout = await fetch(url.href, { headers: await getAuthHeaders(auth0) }).then((res) => res.json());

		if (beginCheckout.err !== undefined) setStatus({ error: beginCheckout.err });
		else window.location.href = beginCheckout.url;
	};

	const closeSigninPrompt = () => setNotSignedInPrompt(false);
	const closeEnterQuestionPrompt = () => {
		setEnterQuestionPrompt(false);
		setAnswers([]);
	};

	const setAnswer = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const newAnswers = [...answers];
		newAnswers[index] = event.target.value;
		setAnswers(newAnswers);
	};

	const answeredAllQuestions = answers.length === listing.questions.length && answers.every((answer) => answer !== "" && answer !== undefined);

	const disabled = sellerProfile?.acceptingNewJobs === false || listing.disabled === true;

	return (
		<>
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ success: undefined, error: undefined })} />
			<Dialog open={notSignedInPrompt} onClose={closeSigninPrompt}>
				<DialogTitle>{"Sign in"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">You must sign in or create an account to purchase a listing.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeSigninPrompt}>Cancel Purchase</Button>
					<Button onClick={checkout} autoFocus>
						Sign In
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={enterQuestionPrompt} onClose={closeEnterQuestionPrompt}>
				<DialogTitle>Please answer these questions from the seller:</DialogTitle>
				<DialogContent style={{ width: 400 }}>
					<br />
					<Grid container spacing={2}>
						{listing.questions.map((question, i) => {
							const notAnswered = answers[i] === "" || answers[i] === undefined;
							return (
								<Grid item key={i}>
									<DialogContentText style={{ width: 300, color: "black", marginBottom: 10 }} id="alert-dialog-description">
										{question}
									</DialogContentText>
									<TextField
										style={{ width: 300 }}
										value={answers[i]}
										error={notAnswered}
										onChange={setAnswer(i)}
										label={notAnswered ? "You must answer before proceeding" : `Question ${i + 1}`}
										multiline
										minRows={2}
									/>
								</Grid>
							);
						})}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeEnterQuestionPrompt}>Cancel Purchase</Button>
					<Button onClick={checkout} disabled={!answeredAllQuestions} autoFocus color="info">
						Checkout
					</Button>
				</DialogActions>
			</Dialog>
			{disabled && (
				<Card
					elevation={12}
					style={{ width: 180, filter: "brightness(95%)", position: "absolute", zIndex: 999, marginTop: 35, marginLeft: 35, textAlign: "center" }}
				>
					<CardContent>Currently unavailable</CardContent>
				</Card>
			)}
			<Card
				elevation={raised ? 12 : 3}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				style={{
					width: 250,
					borderRadius: 2,
					...(disabled
						? {
								filter: "brightness(60%)",
								opacity: 0.6,
								pointerEvents: "none",
						  }
						: {}),
				}}
			>
				<a onClick={checkout}>
					<CardActionArea>
						{listing.image ? (
							<CardMedia component="img" height="140" image={`${imageEndpoint}/${listing.image}/256`} />
						) : (
							<MissingImage style={{ height: 150, width: "100%", backgroundColor: "main" }} />
						)}
						<CardContent>
							<Typography gutterBottom variant="h5" component="div">
								{listing.name}
							</Typography>
							<Typography variant="body2" color="text.secondary" style={{ height: 100, overflow: "hidden", whiteSpace: "pre-line" }}>
								{listing.description}
							</Typography>
						</CardContent>
					</CardActionArea>
				</a>
				<CardActions style={{ padding: 10, paddingLeft: 20 }}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
						{sellerProfile !== undefined && (
							<SellerPreview sellerProfile={sellerProfile}>
								<SellerProfileButton onClick={() => navigate(`/sellerProfile?id=${sellerProfile.id}`)} sellerProfile={sellerProfile} />
							</SellerPreview>
						)}
						<Button onClick={checkout} color="secondary">{`$${listing.price} NZD`}</Button>
					</Grid>
				</CardActions>
			</Card>
		</>
	);
};
