import { Grid, Typography } from "@mui/material";
import { Header, Footer } from "../../components";

export const OurStory = () => (
	<>
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20 }}>
			<Grid item>
				<Header />
			</Grid>
			<br />
			<br />
			<br />
			<Grid item>
				<Grid container justifyContent="center" spacing={5} style={{ maxWidth: 1200 }}>
					<Grid item>
						<Typography variant="h1">LexGo do a deal</Typography>
					</Grid>
					<Grid item>
						<img src="https://imagedelivery.net/_ChdlaDsiJu2L3LdbsV6Jg/b21031f3-4ffc-4fec-5f12-092b89895e00/1920" />
					</Grid>
					<Grid item>
						<Grid container direction="row" spacing={5}>
							<Grid item xs={6}>
								<Typography variant="h3">What we offer</Typography>
								There’s a contract tailored for every deal, and you’ll find that contract on LexGo. We are an online marketplace built for exchanging legal solutions,
								available on-demand. Our purpose is to connect everyday New Zealanders with the best and brightest legal content creators.
							</Grid>
							<Grid item xs={6}>
								<Typography variant="h3">What we do</Typography>
								We’re creating a new model for how small businesses, freelancers and individuals have access to contracts. Rather than paying for legal services by the
								hour or scouring the internet only to end up with something not fit for purpose, LexGo provides a fast and affordable way of fulfilling legal
								requirements.
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		<br />
		<br />
		<Footer />
	</>
);
