import { Grid, Typography } from "@mui/material";
import { Header, Footer } from "../../components";
import { NewSellerMailchimpForm } from "./mailchimp/NewSellerMailchimp";

export const NewSeller = () => (
	<>
		<Header />
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20, textAlign: "center" }}>
			<br />
			<br />
			<Grid item style={{ maxWidth: 900 }}>
				<Typography variant="h2">Want to become a seller on LexGo?</Typography>
				<Typography variant="h5">Give us your details and we will be in contact!</Typography>
				<br />
				<br />
			</Grid>
			<br />
			<br />
			<Grid item>
				<NewSellerMailchimpForm />
			</Grid>
		</Grid>
		<br />
		<br />
		<Footer />
	</>
);
