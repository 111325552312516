import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

export const Auth0ProviderWithHistory = ({ children }: { children: any }) => {
	const navigate = useNavigate();
	return (
		<Auth0Provider
			domain="lexgo.au.auth0.com"
			clientId="ah3whkwhiVgMAw7tLkAC3Urn02tRm430"
			audience="https://api.lexgo.co.nz"
			redirectUri={window.location.origin}
			onRedirectCallback={(appState) => navigate(appState?.returnTo?.replace(window.location.origin, "") || window.location.pathname)}
		>
			{children}
		</Auth0Provider>
	);
};
