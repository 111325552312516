import { imageEndpoint } from "@lexgo/types/constants";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

export const FilePreview = ({ id, children, placement = "right" }: { id?: string; children: JSX.Element; placement?: TooltipProps["placement"] }) => {
	if (id === undefined) return children;
	return (
		<Tooltip
			title={<img src={`${imageEndpoint}/${id}/1920`} width={400} />}
			placement={placement}
			componentsProps={{ tooltip: { style: { maxWidth: 512, marginLeft: 100 } } }}
			followCursor
		>
			{children}
		</Tooltip>
	);
};
