import { useEffect, useState } from "react";

import { AppBar, Toolbar, Typography, LinearProgress, Grid } from "@mui/material";

// Components
import { ListingCard, StatusSnackbar } from "../../../components";

// Helpers
import { fetchListings } from "../../helpers";

// Types
import type { Listing } from "@lexgo/types/Jobs";

export const SellerListings = ({ id }: { id: string }) => {
	const [status, setStatus] = useState<{ error?: string; success?: string; loading: boolean }>({ loading: true });
	const [listings, setListings] = useState<Listing[]>();

	useEffect(() => {
		fetchListings(id).then((listingsRes) => {
			if (listingsRes.err !== undefined) return setStatus({ error: `Failed to load listings! ${listingsRes.err}`, loading: false });

			setStatus({ loading: false });
			setListings(listingsRes._);
		});
	}, [id]);

	return (
		<>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6">Seller Listings</Typography>
				</Toolbar>
			</AppBar>
			<StatusSnackbar success={status.success} error={status.error} onClose={() => setStatus({ success: undefined, error: undefined, loading: status.loading })} />
			{status.loading ? <LinearProgress /> : null}
			{listings !== undefined ? (
				<Grid container direction="row" style={{ padding: "50px" }} spacing={4}>
					{listings.map((listing) => (
						<Grid item key={listing.id}>
							<ListingCard listing={listing} />
						</Grid>
					))}
				</Grid>
			) : null}
		</>
	);
};
