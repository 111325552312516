import { Card, CardContent, Grid, Typography, Divider, CardActions, Tooltip, Button } from "@mui/material";
import { JobImage, JobProperties, JobRevisions, ConfirmPrompt, Chat } from "../../components";

import { getAuthHeaders } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";

import { useContext, useState } from "react";
import { JobContext } from ".";

// Types
import type { JobRequest } from "@lexgo/types/Jobs";

type StatusPUTBody = {
	id: JobRequest["id"];
	status: JobRequest["status"];
};

export const JobInfo = ({ job, onClose }: { job: JobRequest; onClose: () => void }) => {
	const update = useContext(JobContext);
	const [chatOpen, setChatOpen] = useState(false);
	const auth0 = useAuth0();

	const updateStatus = (status: "completed" | "changesRequested") => async () => {
		const putBody: StatusPUTBody = { id: job.id, status };
		fetch(`https://api.lexgo.co.nz/v1/jobs/status`, { method: "PUT", body: JSON.stringify(putBody), headers: await getAuthHeaders(auth0) }).then(update);
	};

	return (
		<Card elevation={12} style={{ width: 400, borderRadius: 2 }}>
			<JobImage image={job.listing.image} />
			<CardContent style={{ height: 420, overflow: "scroll" }}>
				<Grid container spacing={2} direction="column" justifyContent="center">
					<JobProperties job={job} />
					<Grid item>
						<Typography variant="h5">Revisions</Typography>
						<Grid container direction="row">
							{job.revisions.length === 0 ? <Typography variant="body2">The seller has not provided any revisions yet.</Typography> : <JobRevisions job={job} />}
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
			<Divider />
			<CardActions>
				<Tooltip title="Close job details">
					<Button style={{ color: "black" }} onClick={onClose}>
						Close
					</Button>
				</Tooltip>
				<Tooltip title="Open the chat for this job">
					<Button color="info" size="small" onClick={() => setChatOpen(true)}>
						Seller Chat
					</Button>
				</Tooltip>
				{job.status === "newRevision" && (
					<>
						<span style={{ flexGrow: 1 }} />
						<ConfirmPrompt
							size="small"
							color="success"
							title="Accept the latest revision and complete this job"
							description="Do you want to accept the latest revision provided by the seller? This will mark this job as completed and no further revisions may be requested."
							onYes={updateStatus("completed")}
						>
							Accept
						</ConfirmPrompt>
						<ConfirmPrompt
							size="small"
							color="error"
							title="Decline the latest revision requesting a change"
							description="Do you want to decline the latest revision provided by the seller? This will prompt them to provide a new revision with changes you should have discussed via seller chat."
							onYes={updateStatus("changesRequested")}
						>
							Decline
						</ConfirmPrompt>
					</>
				)}
			</CardActions>
			{chatOpen && <Chat open={chatOpen} onClose={() => setChatOpen(false)} jobId={job.id} userId={job.buyerId} />}
		</Card>
	);
};
