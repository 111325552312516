import { Card, CardMedia, CardContent, Typography, CardActions, Button, Avatar, Grid, CardActionArea } from "@mui/material";
import { imageEndpoint } from "@lexgo/types/constants";

import { Component } from "react";
import { MissingImage } from "../MissingImage";

import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";

export type FakeListing = {
	image?: string;
	name: string;
	description: string;
	price: number;
	seller: {
		name: string;
		image?: string;
	};
};

export const FakeListingCard = withAuth0(
	class FakeListingCard extends Component<WithAuth0Props & { listing: FakeListing }> {
		state: { raised: boolean } = { raised: false };

		onMouseOver = () => this.setState({ raised: true });
		onMouseOut = () => this.setState({ raised: false });

		render = () => {
			const listing = this.props.listing;
			return (
				<>
					<Card elevation={this.state.raised ? 12 : 3} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} style={{ width: 250, borderRadius: 2 }}>
						<CardActionArea>
							{listing.image ? (
								<CardMedia component="img" height="140" image={`${imageEndpoint}/${listing.image}/256`} />
							) : (
								<MissingImage style={{ height: 150, width: "100%", backgroundColor: "main" }} />
							)}
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									{listing.name}
								</Typography>
								<Typography variant="body2" color="text.secondary" style={{ height: 100, overflow: "hidden", whiteSpace: "pre-line" }}>
									{listing.description}
								</Typography>
							</CardContent>
						</CardActionArea>
						<CardActions style={{ padding: 10, paddingLeft: 20 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
								<Button style={{ textTransform: "none", borderRadius: 16 }}>
									<Avatar
										style={{ width: 32, height: 32, marginRight: 10 }}
										alt={listing.seller.name}
										src={listing.seller.image ? `${imageEndpoint}/${listing.seller.image}/256` : "_"}
									/>
									<Typography variant="body1" color="textPrimary">
										{listing.seller.name}
									</Typography>
								</Button>
								<Button color="secondary">{`$${listing.price} NZD`}</Button>
							</Grid>
						</CardActions>
					</Card>
				</>
			);
		};
	}
);
