import Tooltip from "@mui/material/Tooltip";
import { SellerPreviewModule } from "./SellerPreviewModule";

// Types
import type { SellerProfile } from "@lexgo/types/Seller";

export const SellerPreview = (props: { sellerProfile: SellerProfile; children: JSX.Element }) => {
	return (
		<Tooltip
			title={<SellerPreviewModule sellerProfile={props.sellerProfile} />}
			placement="right"
			componentsProps={{ tooltip: { style: { maxWidth: 512, marginLeft: 100 } } }}
			followCursor
		>
			{props.children}
		</Tooltip>
	);
};
