import { Grid, Typography } from "@mui/material";
import { Header, Footer } from "../../components";

export const TermsOfUse = () => (
	<>
		<Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 20 }}>
			<Grid item>
				<Header />
			</Grid>
			<br />
			<br />
			<br />
			<Grid item>
				<Grid container justifyContent="center" spacing={5} style={{ maxWidth: 1200 }}>
					<Grid item>
						<Typography variant="h1">TERMS OF USE</Typography>
						<Typography variant="body2">Last updated: 30 March 2022</Typography>
						<br />
						<br />
						LexGo (“we”, “us”, “our”) issues these terms and conditions which apply to your use of the website at{" "}
						<a href="https://lexgo.co.nz">https://lexgo.co.nz</a> and the applications provided within lexgo.co.nz (together, the “Platform”).
						<br />
						<br />
						By using our Platform, you agree to be bound by these terms and conditions and they shall constitute the entire agreement between us (the “Agreement”).
						<br />
						<br />
						If we change our terms and conditions, we will notify you of those changes here.
						<br />
						<br />
						<br />
						<Typography variant="h3">WEBSITE AVAILABILITY</Typography>
						We do everything in our power to ensure that our website is fully operational and available to you at all times. However, in order to make sure it is up
						to date and offering you the best service, access may sometimes be interrupted or restricted to allow for maintenance or the introduction of new
						facilities and services.
						<br />
						<br />
						<br />
						<Typography variant="h3">FOR GENERAL PURPOSES</Typography>
						We are not a law firm and our Platform does not constitute legal advice. We provide content and information for general purposes only. The content offered
						by our users may or may not be provided by qualified lawyers. If you wish to receive specific legal advice you should seek out your own lawyer.
						<br />
						<br />
						<br />
						<Typography variant="h3">NO DIRECT SALE</Typography>
						Our Platform is a marketplace for the exchange of documents between our users. We do not sell or represent the sale of any goods or services to you
						directly, and provide no guarantees to the satisfaction of your purchases.
						<br />
						<br />
						<br />
						<Typography variant="h3">NO REVERSE ENGINEERING</Typography>
						You agree not to modify, reverse engineer, disassemble or decompile any applications, file contents, software, code bases or any portion thereof the
						Platform. You acknowledge that doing so will cause significant detriment to us and we shall have the right to pursue legal recourse against you.
						<br />
						<br />
						<br />
						<Typography variant="h3">PERMITTED USE OF COPYRIGHT AND TRADEMARKS </Typography>
						Copyright in all information, text and images featured in the Platform, except the listings of our sellers, are owned by or licensed to us. Provided that
						you acknowledge us as the source of the information, you are entitled to save or download one copy of any our content for the purposes of reference, but
						you are not entitled to make any further copies of the work.
						<br />
						<br />
						All property rights remain with us. Other than as provided in these terms and conditions, you may not reproduce (in whole or in part), transmit (by
						electronic means or otherwise), modify, or use for any public or commercial purpose any of our content without our prior written permission.
						<br />
						<br />
						<br />
						<Typography variant="h3">POLICY AGAINST ADVERTISING OUR WEBSITE USING UNSOLICITED EMAIL MESSAGES</Typography>
						We require that all third-party e-mails promoting our Platform to be sent only to those who have agreed to receive such messages. We prohibit any
						advertising of us and our websites using unsolicited email messages. We will terminate our relationship with any third-parties who do not comply with this
						policy.
						<br />
						<br />
						If you feel you’ve been sent unsolicited emails promoting us or our websites and would like to register a complaint, please email us at{" "}
						<a href="mailto:info@lexgo.co.nz?subject=Unsolicited Emails">info@lexgo.co.nz</a>. We will immediately investigate your complaint.
						<br />
						<br />
						<br />
						<Typography variant="h3">BREACH OF THESE TERMS AND CONDITIONS</Typography>
						We have the right, but not the obligation, to monitor any activity and content associated with the Platform. We may investigate any reported violation of
						these terms and conditions and take any action that we deem appropriate. This may include, but is not limited to, issuing warnings, suspending,
						terminating or attaching conditions to your access to and use of the Platform.
						<br />
						<br />
						This Agreement shall be governed by and construed in accordance with the laws of New Zealand, without regard to choice or conflicts of law principles.
						Further, you agree to the jurisdiction of the courts of the New Zealand to resolve any dispute, claim or controversy that relates or arises in connection
						with the Agreements.
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		<br />
		<br />
		<Footer />
	</>
);
