import { useAuth0 } from "@auth0/auth0-react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AppBar, Button, Divider, Grid, Paper, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingComponent, UserAppBar } from "../../components";
import { ActiveJobCard } from "./Jobs";
import { getJobs, isActiveJob, isCancelledJob, isCompletedJob } from "../helpers";
import { Listings } from "./listings/Listings";
import { Profile } from "./Profile";

import type { JobRequest } from "@lexgo/types/Jobs";

enum SellerTabs {
	Listings = "Listings",
	ActiveJobs = "Jobs In Progress",
	CompletedJobs = "Completed Jobs",
	CancelledJobs = "Cancelled Jobs",
}

export const Seller = () => {
	const [selectedTab, setTab] = useState(SellerTabs.Listings);
	const [state, setState] = useState<{ loading: boolean; jobs: JobRequest[] }>({ loading: true, jobs: [] });
	const [showNewListingPrompt, setShowNewListingPrompt] = useState(false);

	const auth0 = useAuth0();

	useEffect(() => {
		getJobs(auth0, "seller").then((jobs) => setState({ loading: false, jobs }));
	}, [auth0, selectedTab]);

	const activeJobs = state.jobs.filter(isActiveJob);
	const completedJobs = state.jobs.filter(isCompletedJob);
	const cancelledJobs = state.jobs.filter(isCancelledJob);

	const columnWidth = "310px";
	return (
		<>
			<UserAppBar seller />
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				style={{
					margin: 0,
					width: "100%",
				}}
			>
				<Grid item style={{ width: columnWidth }}>
					<Paper elevation={3} style={{ height: "800px" }}>
						<Profile />
					</Paper>
				</Grid>
				<Grid item style={{ width: "950px" }}>
					<Paper elevation={3} style={{ height: "800px", overflowX: "hidden", overflowY: "auto" }}>
						<div style={{ position: "relative" }}>
							<TabContext value={selectedTab}>
								<AppBar position="static" enableColorOnDark>
									{state.loading ? (
										<LoadingComponent />
									) : (
										<TabList indicatorColor="secondary" textColor="secondary" onChange={(e, value) => setTab(value)}>
											<Tab label={SellerTabs.Listings} value={SellerTabs.Listings} />
											{activeJobs.length !== 0 && <Tab label={SellerTabs.ActiveJobs} value={SellerTabs.ActiveJobs} />}
											{completedJobs.length !== 0 && <Tab label={SellerTabs.CompletedJobs} value={SellerTabs.CompletedJobs} />}
											{cancelledJobs.length !== 0 && <Tab label={SellerTabs.CancelledJobs} value={SellerTabs.CancelledJobs} />}
											{selectedTab === SellerTabs.Listings && (
												<Button
													style={{ marginLeft: "auto", marginTop: 5, marginBottom: 5, marginRight: 10 }}
													variant="contained"
													color="secondary"
													onClick={() => setShowNewListingPrompt(true)}
												>
													Add Listing
												</Button>
											)}
										</TabList>
									)}
								</AppBar>
								<TabPanel value={SellerTabs.Listings}>
									<Listings showNewListingPrompt={showNewListingPrompt} closeNewListingPrompt={() => setShowNewListingPrompt(false)} />
								</TabPanel>
								<TabPanel value={SellerTabs.ActiveJobs}>
									{activeJobs.length === 0 ? (
										<Typography variant="h6" style={{ textAlign: "center" }}>
											You have no active jobs
										</Typography>
									) : (
										<Grid container direction="row" spacing={4}>
											{activeJobs.map((job, i) => (
												<Grid item key={i}>
													<ActiveJobCard job={job} />
												</Grid>
											))}
										</Grid>
									)}
								</TabPanel>
								<TabPanel value={SellerTabs.CompletedJobs}>
									<Grid container direction="row" spacing={4}>
										{completedJobs.map((job, i) => (
											<Grid item key={i}>
												<ActiveJobCard job={job} />
											</Grid>
										))}
									</Grid>
								</TabPanel>
							</TabContext>
						</div>
					</Paper>
				</Grid>
				<Grid item style={{ width: columnWidth }}>
					<Grid container direction="column" justifyContent="center" alignItems="center">
						<Grid item style={{ width: columnWidth }}>
							<Paper elevation={3} style={{ padding: "32px", height: "390px" }}>
								<Typography variant="h6">Sales History</Typography>
								<Divider style={{ width: "100%", height: "2px" }} />
								<Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
									<Typography variant="h6">Coming Soon</Typography>
								</Grid>
							</Paper>
						</Grid>
						<br />
						<Grid item style={{ width: columnWidth }}>
							<Paper elevation={3} style={{ padding: "32px", height: "390px" }}>
								<Typography variant="h6">Customer Reviews</Typography>
								<Divider style={{ width: "100%", height: "2px" }} />
								<Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
									<Typography variant="h6">Coming Soon</Typography>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
